.jumbotron {
  border-radius: 24px;
  background-color: transparent;
  isolation: isolate;

  img,
  video {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }

  video {
    width: -webkit-fill-available;
  }
}
