.toast-container {
  position: fixed;
  right: 50px;
  bottom: 50px;
  z-index: 11;

  > :not(:last-child) {
    margin-bottom: 0;
  }
}

.toast {
  width: 100%;
  max-width: 526px;
  border-radius: 12px;
  margin: 20px 0 0;
  // margin-bottom: 0;
  box-shadow: none;

  &.toast-x {
    background: #fff1f1;
    border: 1px solid #da1b25;
  }

  &.toast-v {
    background: #defbe6;
    border: 1px solid #25a149;
  }

  &.toast-ib {
    background: #e8f4ff;
    border: 1px solid #3299ef;
  }

  &.toast-iy {
    background: #fff9eb;
    border: 1px solid #fbbf26;
  }

  .wrap-con {
    padding: 15px 25px;
    gap: 15px;
    display: flex;
    align-items: center;

    .txt {
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      color: #030229;
      margin-bottom: 0;
    }
  }

  .btn-close {
    margin-left: auto;
    background-image: url(../img/i_x_b.svg);
  }
}

// @media (max-width: 991.98px)
@include media-breakpoint-down(md) {
  .toast-container {
    right: 0;
    bottom: 40px;
    padding: 10px;
  }
}
