// mixins
@mixin i-bg {
  content: "";
  display: inline-block;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 100%;
}

@mixin t-ell {
  line-height: 1.4;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

@mixin t-ell2 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  /* autoprefixer: off */
  -webkit-box-orient: vertical;
  // /* autoprefixer: on */
}
