.wrap-empty {
  padding: 80px;
  text-align: center;
  background: rgba(217, 224, 236, 0.2);
  border: 1px solid #d7d9db;
  box-sizing: border-box;
  border-radius: 22px;

  h5 {
    margin: 12px 0 0;
    font-weight: 500;
    font-size: 16px;
    color: #464f60;
  }
}
