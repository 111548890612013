.wrap-carousel {
  .carousel-item {
    // transition: 0.4s;
    transition: 0s;

    &.active {
      transition: 0s;
    }
  }

  .carousel-indicators {
    margin-right: 72px;
    margin-left: 72px;
    margin-bottom: -37px;
    gap: 10px;

    [data-bs-target] {
      transition: none;
      opacity: 1;
      border-radius: 20px;
      background: rgba(217, 224, 236, 0.8);
      margin: 0;
      width: 12px;
      height: 12px;
      min-height: 0;
      flex: 0 0 auto;
      border-top: 0;
      border-bottom: 0;
    }

    .active {
      background: #464f60;
    }
  }

  .carousel-control-next,
  .carousel-control-prev {
    width: 72px;
    opacity: 1;
  }

  .carousel-control-next-icon {
    background-image: url(../img/btn_arr_carouseltop.svg);
  }

  .carousel-control-prev-icon {
    background-image: url(../img/btn_arr_carouseltop.svg);
    transform: rotate(180deg);
  }

  .home-recent {

    .table-recent .th:nth-child(1),
    .table-recent .td:nth-child(1) {
      width: 245px;
    }
  }
}

.carousel.vertical {

  .carousel-item-next:not(.carousel-item-start),
  .active.carousel-item-end {
    transform: translateY(100%);
  }

  .carousel-item-prev:not(.carousel-item-end),
  .active.carousel-item-start {
    transform: translateY(-100%);
  }

  .carousel-control-prev-icon {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10' height='17' fill='none' viewBox='0 0 10 17'%3E%3Cpath fill='%237D8490' fill-rule='evenodd' d='m1.28.427-.707.707L7.72 8.28.573 15.427l.707.707L9.134 8.28 1.28.427Z' clip-rule='evenodd'/%3E%3C/svg%3E%0A");
    transform: rotate(-90deg);
  }

  .carousel-control-next-icon {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10' height='17' fill='none' viewBox='0 0 10 17'%3E%3Cpath fill='%237D8490' fill-rule='evenodd' d='m1.28.427-.707.707L7.72 8.28.573 15.427l.707.707L9.134 8.28 1.28.427Z' clip-rule='evenodd'/%3E%3C/svg%3E%0A");
    transform: rotate(90deg);
  }
}

.banner-notice {
  background: #030229;
  color: #fff;
  text-align: center;
  height: 80px;

  .carousel-item {
    .item-inner {
      display: flex;
      height: 80px;
      align-items: center;
      justify-content: center;
      padding: 0 120px;

      .item-link {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 16px;

        .i {
          width: 28px;
        }
      }

      .content {
        margin: 0;
        color: #fff;
      }

      b.active {
        font-weight: 600;
        color: $c-a;
      }
    }
  }

  .pagenum {
    display: flex;
    gap: 6px;
    position: absolute;
    left: 60px;
    color: #7d8490;
    font-size: 12px;
  }

  .wrap-btn {
    position: absolute;
    top: calc(50% - 15px);
    right: 60px;
    display: flex;
  }

  .carousel-control-prev,
  .carousel-control-next {
    position: relative;
    width: 30px;
    height: 30px;
    border: 1px solid #7d8490;
    opacity: 1;

    &:hover {
      background: rgba(255, 255, 255, 0.2);
    }
  }

  .carousel-control-prev {
    border-radius: 8px 0 0 8px;
    border-right-width: 0;
  }

  .carousel-control-next {
    border-radius: 0 8px 8px 0;
  }

  .carousel-control-prev-icon,
  .carousel-control-next-icon {
    width: 15px;
    height: 15px;
  }

  &.carousel.vertical {
    .carousel-control-prev-icon {
      transform: rotate(180deg);
    }

    .carousel-control-next-icon {
      transform: rotate(0deg);
    }
  }
}

@keyframes slideLtoR {
  from {
    transform: translateX(100%);
  }

  to {
    transform: translateX(-100%);
  }
}

// @media (max-width: 991.98px)
@include media-breakpoint-down(md) {
  .wrap-carousel {

    .carousel-control-next-icon,
    .carousel-control-prev-icon {
      background: none;
    }

    .carousel-indicators {
      gap: 6.5px;
      margin-bottom: -23px;
    }
  }

  .wrap-carousel .carousel-indicators [data-bs-target] {
    width: 8px;
    height: 8px;
  }

  .banner-notice {
    .carousel-item {
      .item-inner {
        padding: 0 50px 0 12px;

        .item-link {
          gap: 12px;

          .i {
            width: 24px;
          }
        }
      }
    }

    .content {
      font-size: 12px;
      line-height: 18px;
      text-align: left;
      width: 100%;
    }

    .wrap-btn {
      top: 0;
      right: 0;
      flex-direction: column;
      padding: 10px;

      .carousel-control-prev {
        border-radius: 8px 8px 0 0;
        border-right-width: 1px;
        border-bottom-width: 0;
      }

      .carousel-control-next {
        border-radius: 0 0 8px 8px;
      }
    }

    &.carousel.vertical {
      .carousel-control-prev-icon {
        transform: rotate(-90deg);
      }

      .carousel-control-next-icon {
        transform: rotate(90deg);
      }
    }
  }

  .carousel {
    .gradienet {
      &::before {
        content: "";
        display: inline-block;
        width: 60px;
        height: 60px;
      }
    }
  }
}