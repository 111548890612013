.onepage {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  padding: 20px;
  &.main-loading {
    h3 {
      font-weight: 600;
      font-size: 24px;
      line-height: 1.5;
      color: #030229;
      margin-top: 22px;
    }
    p {
      font-weight: 500;
      font-size: 20px;
      line-height: 1.5;
      color: #464f60;
    }
  }
}

// @media (max-width: 991.98px)
@include media-breakpoint-down(md) {
  .onepage.main-loading {
    h3 {
      font-size: 18px;
      margin-top: 15px;
    }
    p {
      font-size: 14px;
    }
  }
}
