:root {
  --bg-text: #f0f5ff;
  --text-primary: #1f1f1f;
  --text-cp: #1f1f1f;
  --text-cp-search: #1f1f1f;
  --text-color-02: #2f80ed;
  --text-color-03: #8c8c8c;
  --text-color-04: #595959;
  --text-color-05: #1f1f1f;
  --text-secondary: #595959;
  --text-title: #2F80ED;
  --text-edit: #595959;
  --text-form: #1F1F1F;
  --text-drag-drop: #1F1F1F;
  --text-drag-drop-02: #1F1F1F;
  --text-sub: #595959;
  --text-dd: #595959;
  --text-cp-line-02: #595959;
  --text-color-header: #1f1f1f;
  --text-select-mint: #1f1f1f;
  --c-border-line: #d9d9d9;
  --c-border-line-02: #d9d9d9;
  --c-border-line-03: #f0f0f0;
  --c-border-line-04: #d9d9d9;
  --c-border-line-05: #d9d9d9;
  --c-border-line-table: #c8cacf;
  --c-border-line-edit: #d9d9d9;
  --c-border-input-focus: #40A9FF;
  --c--border-disable-sns: #BFBFBF;
  --c-border-line-dd: #d9d9d9;
  --c-border-line-header: #1F1F1F;
  --c-border-line-modal: #d9d9d9;
  --background-modal: #ffffff;
  --background-modal-mint: #ffffff;
  --background-box-wallet: #f0f5ff;
  --bg-box-mainnet: #ffffff;
  --bg-navbar: #ffffffcc;
  --c-border-navbar: #d9d9d9;
  --c-border-navbar-companies: #d9d9d9;
  --bg-main: #ffffff;
  --c-talken-studio: #2b2a5c;
  --c-navlink-footer: #595959;
  --c-title: #595959;
  --bg-btn-modal-wallet: #f5f5f5;
  --bg-box-companies: #ffffff;
  --bg-box-companies-page: #ffffff;
  --bg-calendar: #f0f5ff;
  --bg-calendar-02: #D6E4FF;
  --bg-neutral: #f0f2ff;
  --bg-input: #ffffff;
  --bg-sidebar-active: #e6f7ff;
  --bg-box-add-launchtool: #f0f5ff;
  --bg-step: #15152f;
  --c-ic-add: #464f60;
  --text-color-breadcrumb: #1f1f1f;
  --text-color-sidebar: #1f1f1f;
  --text-color-sidebar-01: #8c8c8c;
  --bg-circle: #15152f;
  --color-text-step: #8c8c8c;
  --color-stt-step: #8c8c8c;
  --bg-circle-step: #d9d9d9;
  --boder-step: #F0F0F0;
  --text-active-step: #1f1f1f;
  --bg-checked-step: #E6F7FF;
  --checked-step: #1890FF;
  --bd-contract-adr: #d9d9d9;
  --bd-svg-contract: #8c8c8c;
  --step-bg: #F0F5FF;
  --step-bg-color: #d9d9d9;
  --text-pagination-selected: #2F80ED;
  --bg-pagination: #ffffff;
  --bg-box-congratulations: #F0F5FF;
  --c-border-line-creator: #d9d9d9;
  --c-border-line-mint: #d9d9d9;

}

[data-theme='dark'] {
  --bg-text: #1b1b34;
  --text-primary: #ffffff;
  --text-cp-search: #525298;
  --text-cp: #2F8AF5;
  --text-color-02: #ffffff;
  --text-color-03: #d9d9d9;
  --text-color-04: #ffffff;
  --text-color-05: #8d8d8d;
  --text-secondary: #d9d9d9;
  --text-dd: #525298;
  --text-cp-line-02: #8d8d8d;
  --text-title: #2F8AF5;
  --text-edit: #525298;
  --text-form: #8787D6;
  --text-drag-drop: #40406C;
  --text-drag-drop-02: #8787D6;
  --text-sub: #8D8D8D;
  --text-color-header: #ffffffb2;
  --text-select-mint: #EFF1FA;
  --c-border-line: #40406C;
  --c-border-line-02: #40406C;
  --c-border-line-03: #40406C;
  --c-border-line-04: none;
  --c-border-line-05: #525298;
  --c-border-line-table: #40406C;
  --c-border-line-edit: #525298;
  --c-border-input-focus: #2F8AF5;
  --c--border-disable-sns: #525298;
  --c-border-line-dd: #525298;
  --c-border-line-header: #7380a2;
  --c-border-line-modal: #595959;
  --background-modal: #23235c;
  --background-modal-mint: #15152F;
  --background-box-wallet: #ffffff1a;
  --bg-box-mainnet: #ffffff4d;
  --bg-navbar: #02021ecc;
  --c-border-navbar: #40406C;
  --c-border-navbar-companies: #1f1f1f;
  --bg-main: #02021e;
  --c-talken-studio: #ffffff;
  --c-navlink-footer: #bfbfbf; 
  --c-title: #8787D6;
  --bg-btn-modal-wallet: #02021e;
  --bg-box-companies: #1B1B34;
  --bg-box-companies-page: #1890FF;
  --bg-calendar: #0E0F29;
  --bg-calendar-02: #F0F5FF33;
  --bg-neutral: #15152f;
  --bg-input: #ffffff1a;
  --bg-sidebar-active: #15152f;
  --bg-box-add-launchtool: #15152f;
  --bg-step: #ffffff;
  --c-ic-add: #ffffff;
  --text-color-breadcrumb: #8787D6;
  --text-color-sidebar: #8787D6;
  --text-color-sidebar-01: #8787D6;
  --bg-circle: #8787D6;
  --color-text-step: #8787D6;
  --color-stt-step: #ffffff;
  --bg-circle-step: #8787D6;
  --boder-step: #8787D6;
  --text-active-step: #14A1FF;
  --bg-checked-step: #2F80ED;
  --checked-step: #ffffff;
  --bd-contract-adr: #40406C;
  --bd-svg-contract: #40406C;
  --step-bg: #1b1b34;
  --step-bg-color: #40406C;
  --text-pagination-selected: #ffffff;
  --bg-pagination: #1B1B35;
  --bg-box-congratulations: #15152F;
  --c-border-line-creator: #595959;
  --c-border-line-mint: #4F4F7D;

}

body {
  background-color: var(--background-color);
  color: var(--font-color);
}

/* a {
  color: var(--link-color);
} */
/* Custom Dark Mode Toggle Element */
.toggle-theme-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  /* gap: 4px; */
}

.toggle-theme-wrapper span {
  font-size: 28px;
}

.toggle-theme {
  position: relative;
  display: inline-block;
  height: 21px;
  width: 40px;
}

.toggle-theme input {
  display: none;
}

.slider {
  background-color: #1f1f1f;
  position: absolute;
  cursor: pointer;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  transition: 0.2s;
}

.slider:before {
  background-color: #fff;
  bottom: 3px;
  content: '';
  height: 19px;
  width: 19px;
  left: 3px;
  position: absolute;
  transition: 0.4s;
  align-items: center;
  transform: translate(-2px, 2px);
}

input:checked + .slider:before {
  transform: translate(17px, 2px);
  background: #02021e;
}

input:checked + .slider {
  background-color: #ffffff4d;
}

.slider.round {
  border-radius: 34px;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.slider.round:before {
  border-radius: 50%;
  background: #ffffff;
}
.icon-thememode {
  width: 13px;
  height: 13px;
  z-index: 10;
}
