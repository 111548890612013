// dropdown

.dropdown {
  &.dropdown-block .btn {
    width: 100%;
    background: var(--bg-box-companies);
  }

  .dropdown-item-i {
    img {
      max-width: 18px;
    }
  }

  .btn {
    // justify-content: space-between;
    justify-content: start;
  }

  .dropdown-inner {
    display: flex;
    align-content: center;
    gap: 10px;
    flex: 1 0 auto;
    text-align: left;

    >*:not(img) {
      flex: 1 0 auto;
    }
  }

  .dropdown-i,
  .dropdown-toggle>.i,
  .dropdown-item>.i {
    // margin-right: 6px;
    margin-right: 2px;
      path {
        fill: var(--text-primary);
      } 
  }

  &.wrap-btn-share {
    .btn-share {
      padding: 6px;

      &:after {
        display: none;
      }
    }

    .dropdown-menu {
      border-radius: 8px;
    }
  }
}

.dropdown-item:focus,
.dropdown-item:hover,
.dropdown-item.active,
.dropdown-item:active {
  color: inherit;
  background-color: inherit;
}

.dropdown-wallet {
  width: 320px;
  border: 1px solid #f0f2f4;

  p {
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: #464f60;
  }

  .wrap-btn {
    display: flex;
    flex-direction: column;
    gap: 12px;
    width: 100%;
    padding: 15px 30px;

    .btn {
      width: 100%;
      min-height: 38px;
      height: auto;
      font-weight: 600;
      font-size: 14px;
      line-height: 21px;
      text-align: left;
      padding: 8px 20px;
      background: var(--bg-btn-modal-wallet);
      border-radius: 10px;
      margin: 0;
      justify-content: start;
      display: flex;
      align-items: center;
      gap: 0;
      color: var(--text-primary);

      &.btn-a {
        font-weight: 600;
        // color: #fc6825;
        color: #2f80ed;
        background: #f0f5ff;
      }

      .i {
        width: 20px;
        margin-right: 8px;
      }
    }
  }

  .wrap-box {
    border-radius: 14px;

    .thumb {
      width: 24px;
      // height: 24px;
    }

    .box {
      border: 0;
      gap: 12px;
      padding: 15px 30px;
      border-bottom: 0.8px solid var(--c-border-line-03);
      display: flex;
      align-items: center;

      h4 {
        font-weight: 600;
        font-size: 14px;
        line-height: 14px;
        margin-bottom: 0;
        margin-top: 2px;
        color: var(--text-primary);
      }

      span {
        font-weight: 500;
        font-size: 12px;
        line-height: 12px;
        color: var(--text-secondary);
      }

      .price {
        text-align: right;
        margin-left: auto;
      }

      .swap {
        .btn-swap {
          width: 18px;
          height: 18px;
          border-radius: 100px;
          background: url(../img/btn_swap.svg);
          background-position: center center;
          background-size: 100%;
          background-size: cover;
          background-repeat: no-repeat;
          margin: 0;

          &:hover {
            background: url(../img/btn_swap_a.svg);
            background-position: center center;
            background-size: 100%;
            background-size: cover;
            background-repeat: no-repeat;
          }
        }
      }
    }
  }
}
// set image dropdown toggle mode
:root {
  .dropdown-toggle::after {
    background-image: url(../img/i_arr.svg);
  }
}
[data-theme='dark'] {
  .dropdown-toggle::after {
    background-image: url(../img/i_arr_darkmode.svg);
  }
}
.dropdown-toggle::after {
  margin-left: 10px;
  border: 0;
  width: 8px;
  height: 4px;
  margin-left: auto;
  @include i-bg;
}

.dropdown-menu {
  // background: linear-gradient(180deg, #fefeff -16%, #f4f5f8 357.67%);
  // box-shadow: 0px 8px 8px rgba(24, 39, 75, 0.04),
  //   0px 4px 6px rgba(24, 39, 75, 0.08);
  background: var(--background-modal);
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-radius: 5px;
  border: 0;
  padding: 0;
  color: var(--text-primary);
  &.show {
    overflow: hidden;
    margin: 7px 0 !important;
    min-width: 100%;
  }

  .dropdown-divider {
    margin: 0;
  }

  li {
    padding-top: 0;
    padding-bottom: 0;

    &:last-child {
      border-bottom: 0;
    }

    button {
      font-size: 14px;
      line-height: 20px;
      font-weight: 500;
      color: var(--text-primary);
      padding: 10px 18px;
      display: flex;
      align-items: center;

      // gap: 10px;
      &:hover {
        background: none;
        color: #525298;

        svg.i * {
          fill: #525298;
        }
      }

      img,
      svg {
        margin-right: 10px;
      }

      .wrap-thumb,
      .thumb {
        img {
          margin: 0;
        }
      }
    }
  }

  .form-check {
    // padding: 12px 16px;
    // padding-left: 16px;
    padding-left: 0;
    align-items: center;

    &:hover {
      background-color: #feefe8;
    }

    .form-check-input {
      // width: 14px;
      padding-left: 16px;
      width: 30px;
      height: 44px;
      margin-left: 0;
      position: relative;

      &:after {
        position: absolute;
        top: 15px;
        left: 16px;
      }
    }

    .dropdown-item {
      padding: 0;
      font-weight: 500;
      font-size: 14px;
      line-height: 44px;
      color: #030229;
    }

    .form-check-input:after {
      width: 14px;
      height: 14px;
      background-image: url(../img/dwcheck_g.svg);
    }

    .form-check-input:checked {
      &:after {
        background-image: url(../img/dwcheck_a_f.svg);
      }

      +.dropdown-item {
        color: #fc6825;
      }
    }
  }
}

// @media (max-width: 991.98px)
@include media-breakpoint-down(md) {
  .dropdown-menu {
    .form-check {
      .dropdown-item {
        height: 35px;
        line-height: 35px;
      }

      .form-check-input {
        height: 35px;

        &:after {
          top: 10px;
        }
      }
    }

    li {
      button {
        font-weight: 500;
        font-size: 12px;
      }
    }

    &.show {
      margin: 7px 0 !important;
      border-radius: 12px;
    }
  }

  .dropdown-wallet {
    // border: 0;

    .wrap-btn {
      padding-left: 57px;
      padding-right: 57px;
    }

    .wrap-box {
      padding: 0;

      .box {
        padding: 16px 57px;
      }
    }
  }
}