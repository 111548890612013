@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap');
@import url('https://cdn.jsdelivr.net/gh/orioncactus/pretendard/dist/web/static/pretendard-dynamic-subset.css');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url("https://cdn.jsdelivr.net/gh/orioncactus/pretendard@v1.3.9/dist/web/static/pretendard.min.css");
.cjk-font {
  font-family: 'Pretendard', -apple-system, BlinkMacSystemFont, Pretentard, 'Segoe UI', 'Poppins', 'Oxygen', 'Ubuntu', 'Cantarell',
  'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
}

$directions: (
  'l': 'left',
  't': 'top',
  'r': 'right',
  'b': 'bottom',
);
// admin
.container-admin {
  position: relative;
  display: grid;
  overflow: hidden;
  grid-template-columns: 285px 1fr;
  grid-template-rows: 72px 1fr;
  height: 100vh;
  grid-template-areas:
    "header header"
    "sidebar c-main"
    "sidebar footer";
  font-size: 16px;
  background: var(--bg-neutral);
  &.logout {
    grid-template-areas:
      "header"
      "c-main"
      "footer";

    aside.admin-side {
      display: none;
    }

    .container-main,
    nav.gnb {
      width: 100vw;
      position: fixed;
    }

    .container-main {
      //padding-top: 40px;
      height: 100vh;
      background: var(--bg-main);
    }
    // .footer {
    //   margin-top: 40px;
    //   position: fixed;
    //   bottom: 0;
    //   width: 100vw;
    // }
  }

  .btn {
    gap: 5px;
    font-size: 16px;
    height: 44px;
    font-weight: 600;

    span.txt {
      font-weight: 600;
    }

    &.btn-arrow-circle {
      padding: 0;
      width: 30px;
      height: 30px;
      border: 1px solid #464f60;
      background: #ffffff;
      background-repeat: no-repeat;
      background-position: 45% center;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='6' height='10' fill='none' viewBox='0 0 6 10'%3E%3Cpath fill='%23626978' fill-rule='evenodd' d='M5.566.435a.8.8 0 0 1 0 1.13L2.13 5l3.435 3.435a.8.8 0 1 1-1.132 1.13l-4-4a.8.8 0 0 1 0-1.13l4-4a.8.8 0 0 1 1.132 0Z' clip-rule='evenodd'/%3E%3C/svg%3E%0A");
      top: calc(50% - 15px);

      // background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' fill='none' viewBox='0 0 30 30'%3E%3Cpath fill='%23fff' d='M0 15C0 6.716 6.716 0 15 0c8.284 0 15 6.716 15 15 0 8.284-6.716 15-15 15-8.284 0-15-6.716-15-15Z'/%3E%3Cpath fill='%23464F60' fill-rule='evenodd' d='M15 1C7.268 1 1 7.268 1 15s6.268 14 14 14 14-6.268 14-14S22.732 1 15 1Zm0-1C6.716 0 0 6.716 0 15c0 8.284 6.716 15 15 15 8.284 0 15-6.716 15-15 0-8.284-6.716-15-15-15Z' clip-rule='evenodd'/%3E%3Cpath fill='%23626978' fill-rule='evenodd' d='M16.566 10.434a.8.8 0 0 1 0 1.132L13.13 15l3.435 3.434a.8.8 0 1 1-1.132 1.132l-4-4a.8.8 0 0 1 0-1.132l4-4a.8.8 0 0 1 1.132 0Z' clip-rule='evenodd'/%3E%3C/svg%3E%0A");
      &.btn-arrow-circle-left {
        transform: rotate(-180deg);
      }
    }

    &.hover-fill-a:hover {
      svg * {
        fill: $c-main;
      }
    }

    &.btn-line {
      height: 67px;
      // border: 1px solid #ccd2e3;
      border: 1px solid var(--c-border-line);
      background: var(--bg-text);
      border-radius: 6px;
      padding: 10px;

      &:hover {
        background: var(--bg-text);
        border: 1px solid $c-main;

        svg * {
          fill: $c-main;
        }
      }
    }

    &.btn-sq {
      padding: 0;
      width: 44px;
      height: 44px;
      flex: 0 0 auto;
      svg {
        path {
          fill: var(--c-ic-add);
        }
      }
    }

    &.btn-tr {
      width: 30px;
      height: 30px;

      &:hover {
        svg * {
          fill: $c-main;
        }
      }
    }

    &.btn-sm {
      font-weight: 500;
      font-size: 14px;
      height: 38px;
    }

    &.btn-md {
      min-width: 160px;
    }

    &.btn-lg {
      font-weight: 600;
      font-size: 16px;
      line-height: 32px;
      height: 44px;
      padding: 10px 30px;
    }

    &.btn-ar {
      width: 146px;
      height: 44px;
      background: #2f80ed;
      border-radius: 6px;
      font-weight: 700;
      font-size: 16px;
      color: #ffffff;
      &:hover {
        background: $btn-hover;
      }
    }

    &.btn-grad {
      overflow: hidden;

      .txt {
        font-weight: 600;
      }
      &:hover {
        background: $btn-hover;
      }
    }
    &.btn-deploy {
      width: 328px;
      height: 44px;
      font-size: 16px;
      line-height: 18.75px;
      padding: 0;
    }

    &.btn-a.disabled,
    &.btn-a:disabled,
    &.btn-grad.disabled,
    &.btn-grad:disabled {
      // opacity: 1;
      // background: #f7f9fc;
      // color: #bac2cf;
      background: #2f80ed;
      opacity: 0.5;
      color: #ffffff;
    }

    &.btn-a-line {

      &.disabled,
      &:disabled {
        // opacity: 1;
        // border-color: #f7f9fc;
        // color: #bac2cf;
        background: #2f80ed;
        opacity: 0.5;
      }
    }

    &.btn-grad-line {
      &:before {
        padding: 2px;
      }

      &.disabled,
      &:disabled {
        &:before {
          display: none;
        }

        // background-clip: inherit;
        // -webkit-background-clip: inherit;
        // background: transparent;
        // opacity: 1;
        // color: #bac2cf;
        // -webkit-text-fill-color: #bac2cf;
        // border: 2px solid transparent;ư
        opacity: 0.5;
      }
    }

    &.btn-light {
      &:hover {
        background: #464f60;
        color: #fff;
      }
    }

    &.btn-dark {
      background: #464f60;
      border: 0;

      &:hover {
        background: #f7f9fc;
        color: #464f60;
        border: 0;
      }
    }

    &.btn-dark-line {
      border: 1px solid #464f60;
      color: #464f60;

      &:hover {
        background: #464f60;
        color: #fff;
      }
    }

    &.btn-add {
      font-size: 20px;
    }

    &.btn-line-light {
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: var(--text-edit);
      border: 0.8px solid var(--c-border-line-edit);
      width: 120px;
      height: 44px;
      padding: 10px 16px;
      border-radius: 6px;
      transition: 0s;
      display: flex;
      justify-content: space-between;
      align-items: center;

      svg>path {
        // stroke: #626978;
      }

      &:hover {
        color: #626978;
        border-color: #626978;
        background: #f7f9fc;

        svg>path {
          stroke: #626978;
        }
      }
    }
  }

  .row {
    // --bs-gutter-x: 14px;
    // --bs-gutter-y: 14px;
    --bs-gutter-x: 0.8rem;
    --bs-gutter-y: 0.8rem;
    // align-items: center;
    width: 100%;
    margin: 0;
  }

  .gap-a {
    // gap-admin
    gap: 14px;
  }

  nav.gnb {
    grid-area: header;
    // height: 66px;
    border-bottom: 1px solid var(--c-border-navbar);
    // min-width: 1440px;
    // padding-left: 40px;
    backdrop-filter: blur(5px);
    background: var(--bg-navbar);
  }

  nav.companies {
    border-bottom: 1px solid var(--c-border-navbar-companies);
  }

  aside.admin-side {
    grid-area: sidebar;
    // border-right: 0.5px solid #ccd2e3;
    padding: 16px 0 36px 24px;
    width: 285px;
    z-index: 1000;
    background: var(--bg-main);
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
    border-right: 1px solid var(--c-border-line-03);
    .menu-top {
      padding: 0 16px 0px 8px;
      display: flex;
      justify-content: space-between;
      gap: 16px;
      .wrap-avt {
        min-width: 60px;
      }
      .thumb {
        min-width: 60px;
        height: 60px;
        border-radius: 10px;
      }

      .wrap-txt {
        // border-bottom: 1px solid var(--c-border-line-03);
        padding: 12px 0 32px 0;

        h3 {
          font-weight: 700;
          font-size: 16px;
          line-height: 19.2px;
          color: var(--text-primary);
        }

        p {
          font-weight: 400;
          font-size: 14px;
          line-height: 18.2px;
          color: var(--text-secondary);
          margin-bottom: 24px;
        }
      }
      .wrap-info-creator {
        padding: 0;
      }
    }
    .line-bottom-menu-top {
      border-bottom: 1px solid var(--c-border-line-03);
      margin-left: -24px;
      margin-bottom: 4px;
    }
    .menu-btm {
      .btn {
        height: 44px;
        padding: 12px 20px;
        text-align: left;
        justify-content: start;
        border-radius: 8px;
        background: transparent;
        border: 1px solid transparent;
        color: #8c919c;
        font-weight: 400;
      }

      .btn-light {
        font-weight: 500;
        font-size: 14px;
        line-height: 22px;
        border-radius: 12px;
        background: #f7f9fc;
        color: #464f60;

        &:hover {
          // background: #fff0e9;
          // color: #fc6825;
          // border: 1px solid #fc6825;
          background: #e6f7ff;
          color: #2f80ed;
          border: 1px solid #2f80ed;

          svg * {
            // fill: #fc6825;
            fill: #2f80ed;
          }
        }
      }

      .btn-add {
        width: calc(100% - 18px);
        justify-content: center;
        margin: 10px 0 12px 0;
      }
    }

    .accordion-item {
      border: 0;
      border-radius: 0;
      background: transparent;
      margin-bottom: 3px;
    }

    .accordion-button {
      position: relative;
      padding: 0 22px 0 0;
      font-weight: 500;
      font-size: 16px;
      line-height: 22px;
      color: var(--text-title);
      height: 60px;

      &::after {
        // background-image: url(../img/i_arr_round_light.svg);
        width: 20px;
        height: 20px;
      }

      &.active:not(.btn):before {
        width: 4px;
        height: 40px;
        background: #2f80ed;
        border-radius: 4px 0 0 4px;
        position: absolute;
        right: 0;
        @include i-bg;
      }
    }

    .accordion-body {
      padding: 0 16px 0 0;

      .wrap-menu {
        display: flex;
        flex-direction: column;
        gap: 8px;
        padding: 4px 0;
      }

      .btn.li-item {
        padding: 10px;
        height: 40px;
        gap: 10px;
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
        color: #8787D6;
        border-radius: 8px !important;

        &.active {
          // border-color: #ffebe6;
          background: #2F80ED;
          color: #ffffff;
          font-weight: 500;

          svg * {
            fill: #ffffff;
          }
        }

        //&.active,
        //&:hover {
        //  border-color: #1890ff;
        //  // background: #FFF5F2;
        //}
      }
    }

    .accordion-inner {
      .accordion-button {
        &:after {
          background-image: icon(arrow, 697a8d);
          width: 8px;
        }

        &.active:after {
          background-image: icon(arrow, ffffff);
        }
      }

      .accordion-body {
        display: flex;
        flex-direction: column;
        gap: 8px;
        padding-top: 8px;
        padding-right: 0;
        overflow: visible;

        .btn.li-item {
          font-weight: 400;
          font-size: 14px;
          line-height: 23px;
          color: #8787D6;
          padding-right: 0;
          border: 0;
          width: calc(100% + 18px);
          // height: 38px;

          &:before {
            @include i-bg;
            width: 24px;
            height: 24px;
            background-size: auto;
            background-position-x: 60%;
            //background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='6' height='6' fill='none' viewBox='0 0 6 6'%3E%3Cpath fill='%23435971' fill-rule='evenodd' d='M3 0C1.346 0 0 1.346 0 3s1.346 3 3 3 3-1.346 3-3-1.346-3-3-3Z' clip-rule='evenodd'/%3E%3Cpath fill='%23fff' fill-opacity='.4' fill-rule='evenodd' d='M3 0C1.346 0 0 1.346 0 3s1.346 3 3 3 3-1.346 3-3-1.346-3-3-3Z' clip-rule='evenodd'/%3E%3C/svg%3E%0A");
            background-image: url("../../../assets/icon/dot_sidebar_01.svg");
          }

          &.active {
            background: transparent;
            border: 0;
            font-weight: 700;
            color: #2F8AF5;

            &::before {
              background-image: url("../../../assets/icon/dot_sidebar.svg");
            }
          }
        }
      }
    }
  }

  //admin-main
  .container-main {
    grid-area: c-main;
    height: calc(100vh - 75px);
    width: calc(100vw - 285px);
    overflow: auto;
    display: flex;
    flex-direction: column;
    padding: 0;
    background: var(--bg-main);
    position: relative;

    &.container-fluid {
      width: 100%;

      main {
        max-width: none;
      }
    }
  }
  .footer {
    grid-area: footer;
    height: 100vh;
  }
  .game-main {
    .wrap-thumb.jumbotron {
      height: 280px;
      border: 1.5px solid var(--c-border-line);
      border-radius: 16px;
    }

    .box-item {
      display: flex;
      flex-direction: column;
      position: relative;
      align-items: center;
      gap: 12px;
      // width: 248px;
      height: 180px;
      padding: 16px 26px;
      border: 1.5px solid var(--c-border-line-02);
      border-radius: 16px;
      align-items: center;
      h3 {
        color: var(--text-primary);
        margin: 0;
        font-weight: 700;
        font-size: 16px;
      }
      .wrap-txt {
        display: grid;
        place-items: center;
        h3 {
          color: var(--text-primary);
          margin: 0;
          font-weight: 700;
          font-size: 16px;
        }

        p {
          color: var(--text-secondary);
          margin: 6px 0 0;
        }
      }

      .tag {
        position: absolute;
        left: 20px;
        top: -15px;
        border-radius: 20px;
        font-size: 14px;

        &.active {
          background: $c-main;
          color: #fff;
        }
      }

      &:hover {
        background: var(--bg-sidebar-active);
        border-color: #1890ff;

        h3{
          color: #1890ff;
        }
      }
    }

    .btn-sns {
      // border-color: #9c9c9c;
      border: 1px solid var(--text-primary);
      border-radius: 8px;
      padding: 0;
      width: 44px;
      height: 44px;
      margin-left: 8px;
      &:disabled {
        border: 1px solid var(--c--border-disable-sns);
        svg {
          rect {
            fill: var(--c--border-disable-sns);
          }
          path {
            fill: var(--c--border-disable-sns);
          }
        }
      }
    }
  }

  &.logout main {
    // padding: 50px 60px 35px 60px;
    padding: 43px 40px 35px 40px;
    width: 1280px;
  }

  .box-sq {
    width: 67px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }

  main {
    // grid-area: main;
    margin: 0px auto 70px;
    padding: 0;
    color: var(--text-primary);
    min-width: 1140px;
    max-width: 1336px;
    width: 100%;
    // min-height: calc(100vh - 480px);
    // align-self: center;
    // flex: 1 0 auto;
    overflow: visible;
    background: var(--bg-main);
    // border-radius: 16px;
    .wrap-table {
      position: relative;

      .tr {
        display: flex;
      }

      .table {
        border-color: #dadcdf;

        h5,
        p {
          margin-bottom: 0;
        }

        h5 {
          line-height: 22px;
        }

        > :not(caption)>*>* {
          border-bottom-width: 0;
          border-right: 1px solid #dadcdf;

          &:last-child {
            border-right: 0;
          }
        }

        .sticky {
          position: sticky;
        }

        th,
        td,
        .th,
        .td {
          padding: 12px;
        }

        .inner-header {
          background-color: #fff;
          text-align: left;
          font-weight: 600;
          padding-top: 16px;
          padding-bottom: 16px;
          font-size: 18px;
          border-top: 1px solid #dadcdf;
          border-bottom: 1px solid #dadcdf;
        }

        tr.blank td {
          background-color: #fff;
          padding: 0;
        }

        th,
        .th {
          font-weight: 500;
        }

        td,
        td {
          font-weight: 400;

          &.sticky {
            font-weight: 500;
          }
        }

        thead,
        .thead {

          tr,
          .tr {
            border-top: 1px solid #dadcdf;
            border-bottom: 1px solid #dadcdf;
          }

          th,
          .th {
            vertical-align: middle;
          }

          td,
          .td {
            vertical-align: top;
            text-align: center;
            position: relative;

            .d-flex {
              position: absolute;
              top: 0;
              left: 0;
              right: 0;
              bottom: 0;
            }
          }

          .wrap-thumb {
            width: 65px;
            height: 65px;
          }

          h6 {
            font-weight: 400;
            color: #464f60;
          }
        }

        tbody,
        .tbody {
          border-top: 0;

          tr,
          .tr {
            &:nth-child(2n) {
              background: #f7f9fc;
              border-radius: 8px;
            }
          }

          th,
          .th {
            text-align: left;

            h5 {
              display: flex;
              height: 44px;
              align-items: center;
            }
          }

          td,
          .td {
            text-align: right;
            width: auto;
          }
        }
      }

      .table-statistics {
        th {
          min-width: 160px;
        }

        td {
          min-width: 180px;
          max-width: 220px;
          width: 20%;
        }
      }
    }

    .wrap-done {
      padding-top: 14px;
      padding-bottom: 10px;

      h1+p {
        margin-top: 20px;
        margin-bottom: 16px;
      }

      .wrap-thumb {
        width: 214px;
        height: 214px;
        margin: 16px auto;
      }

      h2 {
        // margin-bottom: 38px;
        margin-bottom: 16px;
        font-weight: 500;
      }

      h2+p {
        margin-bottom: 0;
      }
      p {
        font-size: 16px;
        line-height: 24px;
        color: var(--text-primary);
        &.text-introduction {
        color: var(--text-color-05);
        }
      }

      .wrap-boxes {
        margin: 16px 0 0;
      }

      .wrap-address {
        margin: 16px 0 48px 0;
        display: inline-flex;
        padding: 20px 24px;
        border: 1px solid var(--bd-contract-adr);
        border-radius: 12px;
        gap: 20px;
        align-items: center;
        width: 656px;
        height: 100px;
        justify-content: space-between;
        .wrap-txt {
          text-align: left;
        }

        .wrap-btn {
          padding-top: 12px;

          .btn-group {
            height: 34px;
          }

          .btn {
            width: 34px;
            height: 34px;
            border: 0.5px solid var(--bd-svg-contract);
            border-radius: 6px;
            svg {
              path {
                fill: var(--bd-svg-contract);
              }
            }
          }
        }

        p.sm {
          font-size: 12px;
          line-height: 1.5;
          color: var(--text-secondary);
          margin-bottom: 0;
        }
      }

      .wrap-btn {
        padding: 0;
      }
    }

    h1 {
      font-size: 30px;
      font-weight: 500;
      line-height: 40px;
    }

    h2 {
      font-weight: 600;
      font-size: 24px;
      // line-height: 36px;
      line-height: 1.5;

      &.txt-a {
        font-weight: 700;
      }

      &.txt-grad {
        display: inline-block;
        font-size: 38px;
        line-height: 46px;
        font-weight: 500;

        &+p {
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          // color: var(--c-talken-studio);
          color: var(--text-sub);

          &.sm {
            font-size: 16px;
            font-weight: 400;
          }
          &.sm2 {
            font-size: 18px;
            font-weight: 400;
          }
        }
      }
    }

    h3 {
      font-size: 20px;
      line-height: 30px;

      &.desc {
        font-weight: 500;
        font-size: 20px;
        line-height: 30px;
      }
    }

    h4 {
      font-size: 16px;
      line-height: 24px;
      font-weight: 400;
      margin-bottom: 0;
    }

    h5 {
      font-size: 16px;
      line-height: 24px;
    }

    h6 {
      font-size: 14px;
      line-height: 22px;
      font-weight: 400;
      color: var(--text-cp-line-02);
    }

    p {
      font-weight: 400;
      font-size: 14px;
      line-height: 1.5;

      &.sm {
        font-size: 14px;
        color: var(--text-primary);
      }
    }

    .fc-g {
      color: var(--text-secondary);
    }

    .fc-lg {
      color: var(--text-secondary);
    }

    span.num.circle {
      width: 30px;
      height: 30px;
      border-radius: 100px;
      background: var(--bg-circle);
      display: inline-flex;
      justify-content: center;
      align-items: center;
      font-weight: 500;
      font-size: 16px;
      color: var(--bg-main);
    }

    .form-text {
      color: var(--text-sub);
      font-weight: 400;
      line-height: 22px;
      // line-height: 1.5;
      margin: 0;
      // margin: 5px 0 0;

      ul {
        padding-left: 1em;
      }

      li {
        font-weight: 400;
        font-size: 16px;
        line-height: 28px;
        color: rgba(70, 79, 96, 0.7);
      }

      &.dark {
        padding-bottom: 8px;
        // color: var(--text-secondary);

        .sm {
          font-size: 14px;
        }
      }

      &.sm {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: var(--text-color-04);
      }

      b {
        font-weight: 600;
        color: var(--text-secondary);
      }
    }
    .p-list-page {
      font-size: 16px;
      line-height: 24px;
      color: var(--text-sub);
    }

    .txt-a {
      color: $c-main;
    }

    .caution,
    .caution p {
      color: #ff4d4f;

      u {
        font-weight: 600;
      }
    }

    .form-label {
      display: block;
      font-size: 24px;
      line-height: 32px;
      font-weight: 500;
      margin: 0 0 8px 0;

      .sm {
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
      }
    }
    .form-label-title {
      display: block;
      font-size: 16px;
      line-height: 24px;
      font-weight: 500;
      // margin: 0 0 5px 0;
      color: var(--text-form);
      margin-bottom: 4px;
      .sm {
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
      }
    }

    .form-check .form-check-input:not([role="switch"]) {
      width: 28px;
      height: 28px;
      margin-right: 10px;

      &::after {
        width: 28px;
        height: 28px;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' fill='none' viewBox='0 0 30 30'%3E%3Cpath stroke='%23CCD2E3' d='M15 1C7.28 1 1 7.28 1 15s6.28 14 14 14 14-6.28 14-14S22.72 1 15 1Z' clip-rule='evenodd'/%3E%3C/svg%3E");
        // background-image: url("data:image/svg+xml,%3Csvg width='30' height='30' viewBox='0 0 30 30' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg id='Group 10050'%3E%3Cpath id='Path' fill-rule='evenodd' clip-rule='evenodd' d='M15 1C7.2804 1 1 7.2804 1 15C1 22.7196 7.2804 29 15 29C22.7196 29 29 22.7196 29 15C29 7.2804 22.7196 1 15 1Z' stroke='%23CCD2E3'/%3E%3Cpath id='Path_2' fill-rule='evenodd' clip-rule='evenodd' d='M15 8C11.1402 8 8 11.1402 8 15C8 18.8598 11.1402 22 15 22C18.8598 22 22 18.8598 22 15C22 11.1402 18.8598 8 15 8Z' fill='%23CCD2E3'/%3E%3C/g%3E%3C/svg%3E");
      }

      &:checked:after {
        background-image: url("../../../assets/icon/dot_sidebar_28.svg");
        // background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='28' height='28' fill='none' viewBox='0 0 28 28'%3E%3Cpath fill='%23FF2F00' fill-opacity='.16' fill-rule='evenodd' d='M14 0C6.28 0 0 6.28 0 14s6.28 14 14 14 14-6.28 14-14S21.72 0 14 0Z' clip-rule='evenodd'/%3E%3Cpath fill='%23FF2F00' fill-rule='evenodd' d='M14 7c-3.86 0-7 3.14-7 7s3.14 7 7 7 7-3.14 7-7-3.14-7-7-7Z' clip-rule='evenodd'/%3E%3C/svg%3E%0A");
        // background-image: url("data:image/svg+xml,%3Csvg width='30' height='30' viewBox='0 0 30 30' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg id='Group 10049'%3E%3Cpath id='Path' fill-rule='evenodd' clip-rule='evenodd' d='M15 1C7.2804 1 1 7.2804 1 15C1 22.7196 7.2804 29 15 29C22.7196 29 29 22.7196 29 15C29 7.2804 22.7196 1 15 1Z' stroke='%23CCD2E3'/%3E%3Cpath id='Path_2' fill-rule='evenodd' clip-rule='evenodd' d='M15 8C11.1402 8 8 11.1402 8 15C8 18.8598 11.1402 22 15 22C18.8598 22 22 18.8598 22 15C22 11.1402 18.8598 8 15 8Z' fill='%23FF2F00'/%3E%3C/g%3E%3C/svg%3E%0A");
      }
    }

    .wrap-intro {
      ul.basic-list {
        padding-left: 16px;

        li {
          margin-top: 16px;
        }
      }

      .box-item {
        text-align: center;
        gap: 30px;
        padding: 0 12px;

        .wrap-img {
          box-shadow: 0px 4px 8px rgba(101, 111, 135, 0.08);
        }

        h3 {
          font-weight: 600;
          // font-size: 20px;
          font-size: 16px;
          line-height: 26px;
          color: var(--text-primary);
          margin: 25px 0 20px 0;
        }

        p {
          font-weight: 400;
          color: var(--text-secondary);
          margin: 0;
        }

        &.d-flex {
          align-items: center;
          text-align: left;
          padding: 0;

          h3 {
            margin: 0 0 10px 0;
          }
        }
      }

      a.box-item {
        padding: 26px 36px;
        // background: #ffffff;
        background: var(--bg-box-companies);
        border-radius: 12px;
        display: inline-block;

        &.hover-shadow {
          box-shadow: 0px 2px 16px rgba(179, 179, 179, 0.25);
          box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
          min-height: 168px;
          border: 1px solid transparent;
          &:hover {
            border-color: #2f80ed;
          }
        }
      }
    }
    .color-talken-studio {
      color: var(--c-talken-studio);
    }
    .save-all-btn {
      display: flex;
      justify-content: center;
    }

    .wrap-btn {
      padding: 20px 0 0;
      // display: flex;
      gap: 12px;
    }

    .wrap-btn-btm {
      display: block;
      padding-top: 32px;

      .pull-right {
        display: flex;
        gap: 10px;
      }

      .btn {
        min-width: 160px;
        padding-left: 32px;
        padding-right: 32px;
        // background: var(--bg-text);
        // color: var(--text-color-02);
        // border: 1px solid #2f80ed;

        &.btn-grad:hover {
          // background: #fff0e9;
            background: #0c76f2;
            color: #ffffff;
          .txt {
            position: relative;
            // background: $grad;
            // -webkit-background-clip: text;
            // -webkit-text-fill-color: transparent;
            // background-clip: text;
            // text-fill-color: transparent;
          }
        }

        &.btn-grad-line:hover {
          &:before {
            display: none;
          }

          background-clip: inherit;
          -webkit-background-clip: inherit;
          background: $btn-hover;
          opacity: 1;
          color: #fff;
          -webkit-text-fill-color: #fff;
          border: 0;
        }
      }
      //button prev
      .btn-light {
        background: #f0f5ff;
        color: $c-main;
        &:hover {
          background: #e5eeff;
          color: $c-main;
        }
      }

      .col {
        display: flex;
        gap: 10px;
      }
    }

    .dropdown-item.disabled,
    .dropdown-item:disabled {
      color: #ccd2e3;
    }

    nav.admin-nav {
      // margin: 70px 0 65px 0;
      margin: 24px 0;
      border: 0;

      .nav-tabs {
        // height: 60px;
        padding: 0;

        button.nav-link {
          font-size: 16px;
          width: 20%;
          flex: 1 0 auto;
          padding: 0 0 12px 0;
          margin: 0;
          border-bottom: 3.5px solid var(--boder-step);
          color: var(--color-text-step);

          .step {
            position: relative;
            background: var(--bg-circle-step);
            display: inline-flex;
            border-radius: 100px;
            width: 32px;
            height: 32px;
            align-items: center;
            justify-content: center;
            flex: 0 0 auto;
            margin-right: 12px;

            .bg {
              content: "";
              @include i-bg;
              //background-color: #8787D6;
              width: 30px;
              height: 30px;
              border-radius: 100px;
            }

            .num {
              position: absolute;
              left: auto;
              width: 35px;
              color: var(--color-stt-step);
              font-size: 16px;
            }
          }
        }

        button.nav-link.active {
          color: var(--text-active-step);
          border-bottom: 3.5px solid #1890ff;

          .step {
            background: #1890ff !important;

            .num {
              color: #ffffff;
            }
          }
        }

        button.nav-link.checked {
          color: #1890ff;
          border-bottom: 3.5px solid #1890ff;

          .step {
            background: #1890ff;

            .num {
              color: transparent;
            }

            .bg {
              background-color: transparent;
              background-image: url(../img/i_check_w.svg);
              background-size: 17px auto;
            }
          }
        }
      }

      &.admin-nav-ul {
        .nav-tabs {
          gap: 32px;
          border-bottom: 1px solid var(--c-border-line-02);

          button.nav-link {
            flex: 0 0 auto;
            width: auto;
            color: var(--c-title);
            font-size: 20px;
            font-weight: 400;
            line-height: 23.44px;
            letter-spacing: 0.02em;
            border-bottom: 4px solid transparent;
            padding-bottom: 2px;

            &.active {
              // border-color: #ff2f00;
              border-color: #2f80ed;
              color: var(--text-cp);
              font-weight: 700;
            }
          }
        }
      }
    }

    .wrap-breadcrumb {
      border: 0;
      margin-bottom: 8px;
    }

    .breadcrumb {
      margin: 0;

      .breadcrumb-item {
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
        display: flex;
        align-items: center;
        // color: var(--text-color-03);

        a {
          // color: #464f60;
          color: var(--text-color-breadcrumb);

          &:hover {
            text-decoration: underline;
          }
        }
      }
    }

    .wrap-title {
      // margin-bottom: 35px;
      position: absolute;
      top: 16px;
      left: 24px;

      h2,
      h5 {
        margin-bottom: 0;
      }

      h1 {
        font-weight: 500;
        font-size: 30px;
        line-height: 40px;
        margin-bottom: 0;

        +h5.xs {
          font-weight: 400;
          font-size: 14px;
          line-height: 21px;
          color: #464f60;
          margin-left: 30px;
        }
      }

      .wrap-breadcrumb+h1 {
        font-size: 20px;
        line-height: 28px;
        font-weight: 500;
        color: var(--text-primary);
      }

      .desc {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
      }

      &.add {
        display: flex;
        justify-content: space-between;
        position: relative;
        top: 0;
        left: 0;
      }
      &.wrapper-title-featured {
        display: flex;
        justify-content: space-between;
        position: relative;
        top: 0;
        left: 0;
      }
    }
    
    .wrap-con {
      width: auto;
      margin-top: 168px;
      &.wrap-margin-top {
        margin-top: 0;
      }
    }

    .li-box {

      .li,
      .box-item {
        // background: rgba(217, 224, 236, 0.2);
        background: var(--bg-text);
        border-radius: 12px;
        padding: 22px;

        span {
          font-size: 16px;
          color: var(--text-form);
        }

        h4 {
          font-size: 20px;
          margin: 6px 0 0;
          font-weight: 500;
          line-height: 28px;
        }
      }
      .box-congratulations {
        background: var(--bg-box-congratulations);
        border-radius: 16px;
      }

      &.li-box-sum {
        --bs-gutter-x: 2.4rem;
        --bs-gutter-y: 1.6rem;

        .box-item {
          text-align: center;
          background: #f7f9fc;
          border: 1.5px solid #ccd2e3;
          border-radius: 12px;
          padding: 34px;
        }

        h4 {
          font-weight: 400;
          color: #464f60;
        }
      }
    }

    .li-url {
      .label {
        width: 120px;
      }
    }

    .wrap-calendar {
      position: relative;
      display: flex;
      align-items: center;
      width: 256px;
      height: 44px;

      .form-control {
        margin-top: 0;
      }


      .i-calendar {
        position: absolute;
        right: 21px;
        // top: 21px;
        cursor: pointer;
        svg {
          path {
            // fill: var(--text-primary);
          }
        }
      }
    }

    .li-num {
      max-width: 504px;

      .li {
        display: flex;
        gap: 8px;
        align-items: center;
        padding: 0 0 16px 0;
        margin: 0;

        .num {
          display: inline-flex;
          align-items: center;
          justify-content: center;
          width: 32px;
          height: 32px;
          border-radius: 100px;
          color: var(--bg-main);
          background: var(--bg-circle);
          font-weight: 500;
        }

        p {
          margin: 0;
          color: var(--text-form);
          font-size: 16px;
          font-weight: 400;
          line-height: 24px;
        }
        &:nth-child(5) {
          padding: 0;
        }
        &:nth-child(6) {
          padding: 0;
        }
      }
    }

    .li-card-col {
      .row {
        grid-template-columns: repeat(auto-fill, 1fr);
        grid-auto-rows: auto;
        --bs-gutter-x: 0;
        --bs-gutter-y: 0;
      }

      .card,
      .card .wrap-thumb,
      .card .wrap-thumb .thumb img,
      .card .wrap-thumb .thumb video {
        border-radius: 12px;
      }

      .card-body {
        h3 {
          font-weight: 600;
          font-size: 16px;
          line-height: 24px;
        }
        h4 {
          overflow: hidden;
          word-break: break-all;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 1 !important;
        }
      }

      .card-btm {
        margin-bottom: 5px;
        margin-top: auto;
      }

      .dropdown-toggle.btn-more {
        width: 34px;
        height: 34px;
        padding: 0;
        justify-content: center;

        &:after {
          display: none;
        }
      }
    }

    span.status,
    span.tag {
      padding: 5px 16.5px;
      font-weight: 500;
      line-height: 22px;
      font-size: 14px;
      color: $c-main;
      background: var(--bg-text);
      border-radius: 6px;

      &.active {
        color: $c-main;
        background: var(--bg-box-add-launchtool);
      }

      &.deployed {
        color: #2F80ED;
        background: #15152F;
      }

      &.disabled {
        color: #bac2cf;
        background: #f7f9fc;
      }

      // &.soldout {
      //   background: #ccd2e3;
      //   color: #fff;
      // }

      &.size-lg {
        width: 88px;
        height: 38px;
        font-size: 20px;
      }
    }

    .wrap-time {
      color: #697a8d;

      time {
        font-weight: 400;
        font-size: 14px;
        line-height: 23px;
        color: #697a8d;
      }
    }

    span.btn-alarm {
      cursor: default;
      background: #ffeae6;
      border: 1px solid #ff2f00;
      border-radius: 100px;
      padding: 12px 50px;
      height: auto;
      height: 53px;

      .wrap-txt {
        font-weight: 500;
        font-size: 18px;
        line-height: 27px;
        color: #ff2f00;
      }
    }

    .tab-content {
      padding: 0;
    }

    .dropdown-menu {
      border-radius: 6px;
    }

    .wrap-category {
      display: flex;

      .category-item {
        position: relative;
        background-color: var(--bg-text);
        border: 1px solid $c-main;
        border-radius: 12px;
        padding: 25px 30px;
        margin: 5px;
        text-align: center;
        color: $c-main;

        .remove {
          position: absolute;
          top: 0px;
          right: 0px;
          color: $c-main;
        }
      }
    }
    .wrap-whitelist {
      .li-box-approve {
        display: flex;
        flex-direction: column;
        gap: 20px;
        margin-top: 20px;
      }
      .li-line-filter,
      .li-line-group {
        .li-item {
          display: flex;
          padding: 32px 0;
          border-bottom: 1px solid #ccd2e3;
          align-items: center;
        }
        .box-approve {
          border: 1px solid var(--c-border-line-02);
          border-radius: 12px;
          padding: 16px 24px;
          background: var(--bg-box-companies);
        }

        .wrap-con {
          display: flex;
          gap: 30px;
          align-items: center;

          .wrap-thumb {
            width: 80px;
            height: 80px;
          }

          .wrap-tags {
            display: flex;
            gap: 6px;
          }

          .wrap-tags,
          .wrap-tag {
            align-items: center;

            .txt-sm {
              margin-bottom: 0;
              // color: #7d8490;
              color: #ccd2e3;
              font-size: 14px;
              font-weight: 400;
            }
          }
        }

        .wrap-btn {
          display: flex;
          align-items: center;
          margin-left: auto;
          gap: 15px;
          padding: 0;
        }
      }

      .wrap-form-btm {
        padding-top: 32px;

        .form-text {
          margin-top: 32px;
        }

        .form-check label {
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          color: var(--text-primary);
        }

        .wrap-radio {
          margin-top: 20px;

          .form-check {
            margin-top: 0;
          }
        }
      }
    }

    .wrap-dropslist {
      .drops-filter {
        display: flex;
        justify-content: space-between;
        align-items: center;
        //padding: 12px 0;
        .drops-filter-right {
          display: flex;
        }
        button {
          height: 40px;
        }

        .current-opened {
          min-width: 200px;
          margin-right: 20px;
          label {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 1rem
          }
        }
        .search {
          display: flex;
          justify-content: center;
          align-items: center;
          //margin-right: 20px;
          gap: 1rem;
          position: relative;
          input{
            height: 40px;
            width: 280px;
          }
          input::placeholder{
            color: #525298;
          }
          img {
            position: absolute;
            cursor: pointer;
            top: 50%;
            right: 18px;
            transform: translateY(-50%);
          }
        }
      }
      .li-line-filter,
      .li-line-group {
        .li-item {
          display: flex;
          padding: 16px 24px;
          border: 1px solid var(--c-border-line-04);
          border-color: var(--c-border-line-04);
          border-radius: 10px;
          align-items: center;
          margin: 16px 0;
          background: var(--bg-box-companies);
        }

        .wrap-con {
          display: flex;
          gap: 30px;
          align-items: center;
          margin-top: 0 !important;

          .wrap-thumb {
            border-radius: 8px;
            width: 80px;
            height: 80px;
          }

          .wrap-tags {
            display: flex;
            gap: 6px;
          }

          .wrap-tags,
          .wrap-tag {
            align-items: center;

            .txt-sm {
              margin-bottom: 0;
              // color: #7d8490;
              color: #ccd2e3;
              font-size: 14px;
              font-weight: 400;
            }
          }
        }

        .wrap-btn {
          display: flex;
          align-items: center;
          margin-left: auto;
          gap: 15px;
          padding: 0;
        }
      }

      .wrap-form-btm {
        padding-top: 32px;

        .form-text {
          margin-top: 32px;
        }

        .form-check label {
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          color: #030229;
        }

        .wrap-radio {
          margin-top: 20px;

          .form-check {
            margin-top: 0;
          }
        }
      }
      .pagination-wrap {
        //background-color: grey;
        display: flex;
        justify-content: center;
        align-items: center;
        //padding-top: 10px;
        nav {
          border-bottom: none !important;
        }
      }
    }

    .wrap-duration {
      .li-item {
        min-height: 116px;
        padding: 26px 0;
        display: flex;
        align-items: center;
        border-top: 1px solid #ccd2e3;

        &:first-child {
          border-top: 2px solid #8a97af;
        }

        &:last-child {
          border-top: 2px solid #8a97af;
          border-bottom: 0;
          padding-top: 32px;
          height: auto;
        }

        .col {
          margin: 0;
        }
      }

      .td {
        margin-top: 0;

        p {
          margin: 0;
        }
      }

      .td-title {
        display: flex;
        gap: 10px;

        h3 {
          font-weight: 500;
          font-size: 20px;
          line-height: 30px;
          color: #030229;
          margin: 0;
        }
      }

      .td-time {
        align-items: center;
        font-weight: 500;
        font-size: 14px;
        line-height: 21px;
        color: var(--text-primary);

        time {
          font-weight: 500;
          color: var(--text-primary);
        }

        .line {
          display: flex;
          gap: 10px;
        }

        .wrap-label {
          label {
            width: 40px;
          }
        }

        .wrap-time {
          padding: 0;
          display: flex;
          gap: 16px;
        }
      }
    }

    .wrap-li-royalty {
      h5 {
        font-weight: 500;
        margin-bottom: 0;
      }

      .li-item {
        padding: 34px 40px;
        margin-bottom: 40px;
        box-shadow: 0px 7px 20px rgba(0, 0, 0, 0.05);
        border-radius: 12px;

        .wrap-thumb {
          width: 80px;
          height: 80px;
          border-radius: 12px;
        }

        .wrap-btn {
          padding: 0;
          display: flex;
          gap: 10px;
        }

        .btn {
          height: auto;
        }
      }
    }

    .li-line-radio {
      .li-item {
        position: relative;
      }

      label {
        display: flex;
        position: absolute;
        border: 1px solid var(--c-border-line-02);
        border-radius: 12px;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        gap: 26px;
        align-items: center;
        flex-grow: 1;
        background: var(--bg-box-companies);
      }

      .wrap-li-column {
        gap: 16px;

        .li-item {
          padding: 20px 20px 20px 60px;
          height: 120px;

          &.sm {
            height: 67px;

            label {
              padding-right: 10px;
            }

            h5 {
              font-weight: 500;
              flex: 0 0 auto;
              @include t-ell;
            }

            .wrap-time {
              display: flex;
              gap: 14px;
              align-items: center;

              time {
                font-weight: 400;
                font-size: 16px;
                line-height: 24px;
                color: #030229;
              }
            }

            .wrap-btn {
              padding: 0;
              gap: 6px;

              .btn {
                padding: 0;
                width: 52px;
                height: 52px;
                border-radius: 8px;

                &:hover {
                  background: #f7f9fc;

                  svg * {
                    fill: #7d8490;
                  }
                }
              }
            }

            .form-check-input:checked+label {
              .wrap-btn .btn {
                svg * {
                  fill: #ff2f00;
                }

                &:hover {
                  background: #fee2d5;
                }
              }
            }
          }

          label {
            color: #030229;
          }

          .form-check-input:checked+label {
            background: rgba(255, 47, 0, 0.05);
            border: 1px solid #ff2f00;
            color: #ff2f00;
          }

          .wrap-thumb {
            width: 80px;
            height: 80px;
            border-radius: 10px;
          }
        }

        label {
          padding-left: 94px;

          h5,
          p {
            flex: 1 0 auto;
            margin: 0;
          }
        }
      }

      .wrap-li-row {
        .li-item {
          padding: 30px;
          flex-direction: column-reverse;
          height: 215px;
        }

        label {
          padding: 30px;
          gap: 24px;
          color: #ccd2e3;
          flex-direction: column;

          .i.default {
            display: block;
          }

          .i.active {
            display: none;
          }
        }

        .form-check-input:checked+label {
          border: 1px solid $c-main;
          color: $c-main;

          .i.default {
            display: none;
          }

          .i.active {
            display: block;
          }
        }

        .form-check {
          .form-check-input {
            &:not([role="switch"]) {
              width: 20px;
              height: 20px;
              margin: 0 auto;
            }

            &:after {
              width: 20px;
              height: 20px;
              background-size: 20px 20px;
            }
          }

          .form-check-label {
            svg * {
              fill: #ccd2e3;
            }
          }
        }
      }
    }

    .form-control.is-invalid {
      border: 1px solid var(--c-border-input-focus);
    }

    .invalid-feedback {
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: var(--c-border-input-focus);
      margin-top: 16px;
    }

    .wrap-range {
      .range-lg {
        display: flex;
        flex-direction: column-reverse;
        height: 75px;
        margin-bottom: 20px;

        .range-container {
          border: 0.8px solid #ccd2e3;
          border-radius: 15px;
        }

        .slider-range-block {
          margin: 0;

          .ui-slider-handle:after {
            width: 34px;
            height: 34px;
            background: #ff2f00;
            border: 8px solid #fff;
            box-shadow: 0px 4px 15px rgba(159, 159, 159, 0.25);
            margin: 4.5px 0;
          }

          .ui-slider {
            height: 30px;
            border: 6px solid #fff;
            background: #ededef;
            border-radius: 100px;
            top: auto;
          }

          .ui-slider-range {
            border-radius: 100px;
          }

          .wrap-dot {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            display: flex;
            align-items: center;
            justify-content: space-evenly;
          }

          .dot {
            display: inline-block;
            width: 10px;
            height: 10px;
            border-radius: 100px;
            background-color: #fff;
          }
        }
      }

      .table-btm {
        display: flex;
        flex-direction: column;
        gap: 10px;

        .tr {
          display: flex;
          gap: 10px;
          align-items: center;
        }

        h4,
        h4 span {
          font-weight: 500;
          font-size: 18px;
          line-height: 27px;
          color: #030229;
          margin-bottom: 0;
        }

        h4 span {
          margin-right: 10px;
        }

        .tag {
          background: #464f60;
          border-radius: 22px;
          height: 36px;
          padding: 6px 22px;
          font-weight: 600;
          font-size: 18px;
          line-height: 27px;
          text-align: center;
          color: #ffffff;
          margin-bottom: 10px;

          &.tag-a {
            background: #ff2f00;
          }
        }
      }
    }

    .dropdown-whitelist {
      li {
        .dropdown-item {
          display: flex;
          align-items: center;
          gap: 10px;
          padding: 9px 14px;
        }

        .wrap-thumb {
          width: 45px;
          height: 45px;
          border-radius: 6px;
        }

        .wrap-txt {
          max-width: 192px;

          h5 {
            font-weight: 500;
            font-size: 14px;
            color: var(--text-primary);
            margin: 0;
            @include t-ell;
          }

          h6 {
            font-weight: 400;
            font-size: 12px;
            color: #7d8490;
            margin: 3px 0 0;
            // max-width: 192px;
            @include t-ell;
          }
        }

        &:hover {
          .wrap-txt {
            h5 {
              color: #2f80ed;
            }

            h6 {
              color: #2f80ed;
            }
          }
        }

        &:first-child .dropdown-item {
          padding-top: 14px;
        }

        &:last-child .dropdown-item {
          padding-bottom: 14px;
        }
      }
    }

    .acc-table {
      .accordion-item {
        overflow: visible;
        padding: 20px 0;
        z-index: 0;
      }

      .wrap-thumb {
        width: 70px;
        height: 70px;
        background: var(--bg-box-add-launchtool);

        .thumb {
          border-radius: 12px;
        }
      }

      .tr {
        padding: 20px 0 0;
        border-top: 1px solid var(--c-border-line-table);
      }

      .thead .th,
      .tbody .td {
        &:nth-child(1) {
          width: 30px;
          display: flex;
          align-items: center;
          text-align: center;
        }

        &:nth-child(2) {
          width: 40px;
          display: flex;
          align-items: center;
          text-align: center;

          &.td {
            font-weight: 500;
            font-size: 20px;
            line-height: 33px;
          }
        }

        &:nth-child(3) {
          width: 70px;
        }

        &:nth-child(4) {
          width: 220px;
        }

        &:nth-child(5) {
          width: 144px;

          .dropdown .btn {
            width: 100%;
          }
        }

        &:nth-child(6) {
          width: 124px;
        }

        &:nth-child(7) {
          width: 115px;
          padding-left: 20px;
          flex: 1 0 auto;
        }

        &:last-child {
          width: auto;
          // flex: 1 0 auto;
          text-align: right;
          display: flex;
          align-items: center;
          justify-content: end;

          .accordion-button {
            margin-left: 0;
          }
        }

        &.colspan {
          flex: 1 0 auto;
          width: auto;
        }
      }

      .tbody .td {

        &:nth-child(1),
        &:nth-child(2) {
          height: 70px;
        }
      }

      .tr.open {
        position: relative;

        .subt {
          align-items: flex-start;
          gap: 18px;
          margin-top: 32px;

          h4,
          legend.h4 {
            font-weight: 600;
            font-size: 16px;
            line-height: 27px;
            margin-bottom: 5px;
          }

          p {
            font-weight: 400;
            font-size: 14px;
            line-height: 1.5;
            margin: 0;
            color: #7d8490;
          }

          .link {
            margin-left: auto;
            margin-top: 6px;
            align-items: inherit;
          }
        }

        label,
        legend {
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
          margin-bottom: 16px;
        }

        .td:last-child {
          position: absolute;
          top: 20px;
          right: 0;
        }

        .wrap-thumb {
          width: 230px;
          // width: 100%;
          height: 230px;
          margin-right: 30px;

          .thumb-file {
            width: 100%;
            height: 100%;

            label {
              height: 100%;
              padding-top: 50px;
            }
          }
        }

        .row-list {
          margin: 22px 0 0;
          // gap: 14px;
        }

        .wrap-props {
          .li {
            background: var(--bg-box-congratulations);
            border: 1px solid transparent;
            border-radius: 16px;
            padding: 20px;
            text-align: center;
            // width: calc(20% - 12px);

            span {
              font-weight: 400;
              font-size: 16px;
              line-height: 24px;
              color: var(--text-form);
            }

            h5 {
              font-weight: 600;
              font-size: 18px;
              line-height: 28px;
              color: var(--text-primary);
              margin: 8px 0 0;
            }
          }
        }

        .wrap-levels,
        .wrap-stats {
          .li {
            padding: 20px 18px;
            border: 1px solid var(--c-border-line-02);
            border-radius: 8px;
          }
        }
      }
    }

    .wrap-box {
      background: var(--bg-text);
      border-radius: 8px;
      padding: 24px;
      text-align: center;

      &.line-dotted {
        background: transparent;
        border: 1px dashed #ccd2e3;
        border-radius: 12px;
        padding: 12px;
      }

      .box-item {
        display: flex;
        background: #ffffff;
        border: 1px solid #ccd2e3;
        border-radius: 16px;
        padding: 24px 35px;
        align-items: center;

        h5 {
          font-weight: 500;
        }

        .wrap-tag {
          display: flex;
          gap: 6px;
        }
      }

      .btn-grad.disabled,
      .btn-grad:disabled {
        // background: #ccd2e3;
        // color: #f7f9fc;
        opacity: 0.5;
      }
    }

    .wrap-box-dotted,
    .wrap-session {
      a {
        display: flex;
        align-items: center;
      }

      .wrap-box.line-dotted {
        a.box {
          background: var(--bg-text);
          border-radius: 16px;
          flex-direction: column;
          padding: 22px;
          font-weight: 500;
          font-size: 20px;
          color: #d9d9d9;

          &:hover {
            background: #7d8490;
            color: #fff;

            svg * {
              fill: #D9D9D9;
            }
          }
        }
      }
    }

    .box-subsc {
      display: flex;
      align-items: center;
      background: #f7f9fc;
      border-radius: 12px;
      padding: 20px;

      .row {
        align-items: center;
      }

      .wrap-thumb {
        width: 170px;
        height: 170px;
        border-radius: 12px;
      }

      .wrap-con {
        margin-left: 50px;
      }

      p {
        color: #7d8490;
      }

      .wrap-input {
        display: flex;
        gap: 15px;
        align-items: center;

        input {
          background: #ffffff;
        }

        h5 {
          margin-bottom: 0;
        }
      }
    }

    .wrap-session {
      gap: 20px;

      .wrap-li {
        display: flex;
        flex-direction: column;
        gap: 20px;
        margin-top: 0;
      }

      .li-item {
        border: 1px solid var(--c-border-line-04);
        border-radius: 12px;
        padding: 16px 24px;
        gap: 32px;
        cursor: pointer;
        background: var(--bg-box-companies);

        .wrap-img .i {
          &.default {
            display: block;
          }

          &.hover {
            display: none;
          }

          &.disabled {
            display: none;
          }
        }

        .btn-grad {
          &:disabled {
            background: #e6e9f1;
            color: #fff;
          }
        }

        p {
          margin: 0;
        }

        h4 {
          font-weight: 500;
          font-size: 18px;
          line-height: 27px;
          color: var(--text-primary);
          margin: 0;
        }

        h5 {
          font-weight: 700;
          font-size: 16px;
          line-height: 24px;
          color: var(--text-primary);
          margin: 0;
        }

        .wrap-con {
          display: flex;
          flex-direction: column;
          flex-grow: 1;
          padding-left: 20px;

          .tr {
            display: flex;

            &.top {
              gap: 32px;
              align-items: baseline;
            }

            &.btm {
              border-top: 0.5px solid #ccd2e3;
              padding-top: 12px;
              margin-top: 12px;
              color: #030229;
              gap: 20px;
            }
          }
        }

        &:not(.disabled):hover {
          // border: 1px solid #fc6825;
          border: 1px solid #2f80ed;
          // background: rgba(255, 47, 0, 0.05);

          .num {
            // background: #fc6825;
            background: #2f80ed;
          }

          .wrap-img .i {
            &.default {
              display: none;
            }

            &.hover {
              display: block;
            }
          }

          .wrap-txt {
            * {
              // color: #fc6825;
              color: #2f80ed;
            }
          }
        }

        &.disabled {
          background: #f7f9fc;
          border: 0;
          cursor: default;

          .num {
            background: #ccd2e3;
          }

          .tag {
            background: #ccd2e3;
            color: #fff;
          }

          h4,
          p {
            color: #ccd2e3;
          }

          .wrap-img .i {
            &.default {
              display: none;
            }

            &.disabled {
              display: block;
            }
          }
        }
      }
    }

    .wrap-box-light {
      background: #f7f9fc;
      border-radius: 12px;
      padding: 25px 30px;

      .wrap-title {
        display: flex;
        gap: 10px;
        align-items: center;
        margin-bottom: 20px;

        h5 {
          font-weight: 600;
          font-size: 16px;
          line-height: 24px;
          color: #464f60;
          margin-bottom: 0;
        }
      }

      p {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: #464f60;
      }
    }

    .li-card-col {
      .wrap-card {
        width: auto;
      }

      .card {
        width: 246px;
        height: 336px;
        gap: 24px;
        background: var(--bg-main);
        border: 1px solid var(--c-border-line-02);

        &.card-create {
          .wrap-thumb {
            // background-color: rgba(217, 224, 236, 0.2);
            background: var(--bg-box-add-launchtool);
            text-align: center;

            .thumb {
              width: 100%;
              background-size: contain;
              background-image: url(../img/img_add_lg_new_01.svg);
              background-size: 50px;
              background-repeat: no-repeat;
            }
          }
        }

        h4 {
          font-weight: 600;
          font-size: 16px;
          line-height: 24px;
          margin-bottom: 5px;
          @include t-ell2;
        }

        h5.alarm {
          font-weight: 500;
          font-size: 14px;
          line-height: 21px;
        }

        .card-btm {
          display: flex;
          justify-content: space-between;
          align-items: center;

          .card-btm-l {
            display: flex;
            gap: 6px;

            +.wrap-txt {
              color: #697a8d;
              font-size: 14px;
              padding: 10px 0;
            }
          }
        }
      }

      &.card-wide {
        .row {
          grid-template-columns: repeat(auto-fill, 342px);
        }

        .card {
          width: 342px;
          height: 205px;

          .wrap-thumb {
            padding-bottom: 110px;
          }

          .thumb {
            img {
              object-fit: cover;
              width: 100%;
              height: 100%;
            }
          }

          .thumb+.tag {
            position: absolute;
            top: 8px;
            left: 8px;
            background: #464f60;
            color: #fff;
            border-radius: 6px;
            font-weight: 500;
            font-size: 14px;
          }
        }
      }
    }
  }

  form {
    font-size: 16px;

    .position-relative {
      .form-control+.btn-inner {
        position: absolute;
        right: 19px;
        top: 12px;
      }
    }

    .wrap-form {
      margin-top: 32px;

      &.disabled {
        * {
          opacity: 0.65;
          cursor: not-allowed;
        }
      }

      .row-col-wrap {
        display: flex;
        gap: 1rem;
        margin-top: 20px;
        margin-bottom: 16px;
        padding-top: 20px;
        border-top: 1px solid var(--c-border-line-table);

        .row-col-text-wrap {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          min-width: 300px;
          flex:1;

          .row-col-text-top {
            display: flex;
            justify-content: space-between;
            gap: 2rem;
            margin-bottom: 24px;
            .row-col-text-top-item {
              flex: 1;
            }
          }
          .title-project-table {
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            margin-bottom: 16px;
            color: var(--text-form);
          }
        }
      }

      .row-col-thead {
        // margin-top: 20px;
        display: flex;
        flex-direction: column;
        gap: 16px;

        .row-col-item-wrap {
          cursor: pointer;
          display: flex;
          gap: 12px;
          // margin: 15px 0;
          // padding: 7px;
          // border-radius: 20px;
          // background-color:  #3045b0;  
          border-bottom: 1px solid var(--c-border-line-03);
          padding-bottom: 16px;

          &:hover {
            opacity: 0.8;
          }
          .row-col-item-img-wrap {
            .row-col-item-img {
              border-radius: 8px;
              width: 60px;
              height: 60px;
              object-fit: cover;
            }
          }

          .row-col-item-info-wrap {
            color: var(--text-primary);
            .row-col-item-info-title {
              font-size: 14px;
              line-height: 22px;
              font-weight: 700;
            }
            .row-col-item-info-description {
              font-size: 12px;
              color: var(--text-secondary);
              line-height: 20px;
            }
          }
        }
      }


      .form-label-button { display: flex; justify-content: space-between; align-items: center}

      .row:first-child .pull-right {
        display: flex;
        align-items: center;
        gap: 14px;
        height: 40px;
      }

      .wrap-li-column {
        display: flex;
        flex-direction: column;
        gap: 16px;

        .li-item {
          display: flex;
          align-items: center;


          .wrap-form-control {
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex-grow: 1;
            position: relative;

            .form-check-input {
              padding: 10px;
              margin: 10px;
            }
            img {
              position: absolute;
              left: 10px;
              width: 20px;
            }
            svg {
              position: absolute;
              left: 10px;
              width: 20px;
            }
            .form-control-url {
              border: 1px solid var(--c-border-line-02);
              background: var(--bg-box-companies);
              border-radius: 6px;
              // height: 67px;
              padding: 10px 40px;
              font-size: 16px;
              line-height: 24px;
              color: var(--text-form);
              width: 100%;
              &:focus {
                border-color: var(--c-border-input-focus);
              }
              &:disabled,
              &[readonly] {
                // background: #f7f9fc;
                background: #f0f5ff;
              }

              &.form-control-sm {
                height: 47px;
                padding: 10px 18px;
              }

              &.form-control-xs {
                height: 38px;
              }

              &.fade {
                position: absolute;
                // width: 0 !important;
                // height: 0 !important;
                padding: 0;
                top: 0;
                left: 0;
                bottom: 0;
                right: 0;
              }

              .form-control-url:disabled,
              .form-control-url[readonly] {
                background: #f7f9fc;
              }
            }
          }
          input::placeholder{
            color: #40406C;
          }
        }

        &.li-sns {
          .label {
            width: 140px;
            font-weight: 500;
            font-size: 18px;
            line-height: 27px;
            color: var(--text-form);
          }
          .form-text {
            text-align: right;
            color: #2f80ed;
            font-size: 14px;
          }
        }
      }
    }

    .wrap-thumb {
      display: inline-block;
      position: relative;
      border-radius: 6px;
      overflow: hidden;
    }

    .wrap-file {
      // position: relative;
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;

      label {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        width: 100%;
        height: 100%;
        padding: 35px;
        margin: 0;
        text-align: center;
        border-radius: 6px;
        border: 1.5px dashed var(--c-border-line-02);
        cursor: pointer;
        background: var(--bg-box-companies);

        .active {
          // color: #ff2f00;
          color: #2f80ed;
        }

        >img {
          width: 32px;
          height: auto;
          margin-top: -5px;
        }

        >div {
          font-weight: 400;
          font-size: 14px;
          line-height: 24px;
          color: var(--text-drag-drop);
        }
        .drag-drop-02 {
          color: var(--text-drag-drop-02);
        }
      }
    }

    .thumb .wrap-file label {
      color: #fff;
      background: rgba(49, 49, 49, 0.7);
      opacity: 0;

      &:hover {
        opacity: 1;
        border: 0;
      }

      .active {
        color: #fff;
      }
    }

    .input-group {
      &.float-start {
        width: calc(100% - 67px - 14px);
      }

      .input-group-text {
        // border-radius: 12px;
        background: transparent;
        color: var(--text-form);
        border: 1px solid var(--c-border-line-02);
        background: var(--bg-box-companies);

        &.gray-light01 {
          color: #ccd2e3;
        }
      }
      .input-group-text-disable {
        background: var(--bg-calendar-02);
      }

      input.before-text {
        border-right: 0;
        padding-right: 0;
      }
      input.before-text-disable {
        border-right: 0;
        padding-right: 12px;
      }

      input+.input-group-text {
        border-left: 0;
        padding: 9px 12px;
      }

      input.form-control-sm+.input-group-text {
        padding: 10px 18px;
      }
    }

    .editor-toolbar {
      //background-color: #3045b0;
      color: black;
    }

    .form-control {
      border: 1px solid var(--c-border-line-05);
      border-color: var(--c-border-line-05);
      background: var(--bg-box-companies);
      border-radius: 6px;
      // height: 67px;
      padding: 10px 12px;
      font-size: 16px;
      line-height: 24px;
      color: var(--text-form);

      &:disabled,
      &[readonly] {
        // background: #f7f9fc;
        background: var(--bg-calendar-02);

      }

      &.form-control-sm {
        height: 47px;
        padding: 10px 18px;
      }

      &.form-control-xs {
        height: 38px;
      }

      &.fade {
        position: absolute;
        // width: 0 !important;
        // height: 0 !important;
        padding: 0;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
      }

      .form-control:disabled,
      .form-control[readonly] {
        background: #f7f9fc;
      }
      &:focus {
        border-color: var(--c-border-input-focus);
      }

      &::placeholder{
        color: #40406C;
      }
    }

    textarea.form-control {
      height: 120px;

      +.count {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        text-align: right;
        color: #464f60;
        margin-top: 15px;
      }
    }

    .row {
      --bs-gutter-x: 14px;
      --bs-gutter-y: 14px;

      .col .form-control {
        margin: 0;
      }
    }

    .form-select,
    .dropdown-toggle {
      border: 1px solid var(--c-border-line-dd);
      border-radius: 6px;
      height: 44px;
      font-weight: 400;
      color: var(--text-dd);
      font-size: 16px;
      padding-left: 20px;
      // min-width: 160px;

      .i {
        max-height: 24px;
      }
    }

    .dropdown-sm {
      height: auto;
      align-items: center;

      .dropdown-toggle {
        height: 40px;
        border-radius: 6px;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: var(--text-dd);
        padding: 10px 16px;
        gap: 16px;
        min-width: 100px;
      }

      .dropdown-item-text {
        padding: 14px;
        font-weight: 400;
        font-size: 14px;
        line-height: 1.5;
        // color: #7d8490;
        color: rgba(70, 79, 96, 0.7);
      }
    }

    .acc-table .dropdown-toggle,
    .acc-table .form-select,
    .acc-table .form-control:not(textarea) {
      height: 44px;
      margin: 0;
    }

    .row+.row {
      // margin-top: --bs-gutter-y;
      margin-top: 0;
    }
  }

  .caution {
    align-items: start;

    img {
      // margin-top: 3px;
    }
  }

  .wrap-btn-range {
    // margin: 20px 0 0;
    gap: 14px;

    .btn-range {
      width: 153px;
      height: 67px;
      background: #ffffff;
      border: 1px solid #ccd2e3;
      border-radius: 12px;
      font-weight: 400;

      &:hover {
        border: 1px solid $c-main;
        color: $c-main;
      }

      &.active {
        background: var(--bg-text);
        border: 1px solid $c-main;
        color: $c-main;
      }
    }
  }

  .wrap-img {
    &.circle {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      background: #ffffff;
      box-shadow: 0px 4px 8px rgba(101, 111, 135, 0.08);
      border-radius: 100px;
    }
  }

  .link-a span {
    font-weight: 600;
    font-size: 16px;
  }

  .pop-admin {
    .pagination {
      .btn-arrow {
        width: 30px;
        height: 30px;

        &:hover {
          background: #f7f9fc;
        }
      }
    }

    .modal-header {
      padding-bottom: 10px;
    }
    .add-properties-body {
      padding: 0 36px 24px 36px;
    }
    h2 {
      font-weight: 500;
      font-size: 20px;
      line-height: 1.5;
    }

    h3 {
      font-weight: 500;
      font-size: 18px;
      line-height: 1.5;
    }

    h4 {
      font-weight: 500;
      font-size: 16px;
      line-height: 1.5;
      color: var(--text-primary);
    }

    h5 {
      font-size: 14px;
      line-height: 1.5;
    }

    .modal-title {
      line-height: 32px;
      font-size: 24px;
      font-weight: 500;
    }

    .box-sq {
      width: 57px;
    }

    .wrap-form {
      margin: 0 0 10px 0;
    }

    .wrap-btn {
      margin-top: 0;
    }

    .row {
      --bs-gutter-x: 8px;
      --bs-gutter-y: 8px;
    }

    .modal-content {
      // min-width: 600px;
      // min-height: 614px;
    }

    .thead {
      padding-bottom: 12px;
    }

    .tbody {
      max-height: 240px;
      overflow-y: auto;
      overflow-x: hidden;

      p {
        font-size: 14px;
        font-weight: 400;
        line-height: 1.5;
        margin-bottom: 0.5rem;
        color: var(--text-color-03);
        text-align: left;
      }
    }
    .add-properties-body {
      padding: 0 36px 24px 36px;
    }

    .th {
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      color: var(--text-primary);

      h4 {
        font-weight: 600;
      }
    }

    .form-control {
      height: 44px;
      border-radius: 6px;
      padding: 12px 14px;
      font-size: 14px;
      background: var(--bg-input);

      &.is-invalid {
        border: 1px solid #fd1717;

        &+p {
          color: #fd1717;
        }
      }

      &::placeholder {
        color: #ccd2e3;
      }

      &+.input-group-text {
        padding: 0 14px;
        font-size: 14px;
        color: #ccd2e3;

        &.text-center {
          background: #f7f9fc;
        }
      }

      &.form-control-lg {
        height: 56px;
        border-radius: 10px;
      }
    }

    .wrap-token {
      display: flex;
      gap: 10px;
      align-items: center;

      .wrap-thumb {
        width: 38px;
        height: 38px;
      }

      h4 {
        font-size: 18px;
        line-height: 27px;
        margin: 0;
      }
    }

    .btn-sq {
      width: 44px;
      height: 44px;
      border-radius: 8px;
      background: transparent;

      &.btn-lg {
        width: 56px;
        height: 56px;
        padding: 0;
      }
    }

    .btn-add {
      font-size: 18px;
    }
  }
 .collections-modal {
  .modal-content {
    min-width: unset;
    min-height: unset;
    height: fit-content;
    width: 400px;
    max-height: 404px;
    .header-collections-modal {
      padding: 16px 20px;
      border-bottom: 1px solid var(--c-border-line-modal);
      .btn-close {
        top: 16px;
        right: 20px;
        width: 32px;
        height: 32px;
      }
    }
    .body-collections-modal {
      padding: 16px 20px;
      overflow: auto;
      form {
        .wrap-form {

        }
      }
    }
  }
 }
  footer {
    // grid-area: footer;
    padding: 0;
    position: relative;
    border-top: 0.5px solid #ccd2e3;
    background: #fff;
    padding: 45px 60px 110px 40px;
    min-width: 1140px;
  }

  &.logout footer {
    padding-left: 60px;
  }
}

.cp-text{
  color: var(--text-cp-line-02);
}

.color-8787D6{
  color: #8787D6 !important;
}

.form-companies {
  display: block;
  width: 100%;
  border: 1px solid var(--c-border-line-04);
  border-color: var(--c-border-line-04);
  background: var(--bg-box-companies);
  border-radius: 6px;
  // height: 67px;
  padding: 10px 12px;
  font-size: 16px;
  line-height: 24px;
  color: var(--text-primary);

  &:disabled,
  &[readonly] {
    // background: #f7f9fc;
    background: var(--bg-calendar);
  }

  &.form-control-sm {
    height: 47px;
    padding: 10px 18px;
  }

  &.form-control-xs {
    height: 38px;
  }

  &.fade {
    position: absolute;
    // width: 0 !important;
    // height: 0 !important;
    padding: 0;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }

  .form-control:disabled,
  .form-control[readonly] {
    background: #f7f9fc;
  }
  &:focus {
    border-color: var(--c-border-input-focus);
  }
} 
@for $i from 1 through 500 {
  .p-#{($i - 1) * 2} {
    padding: #{($i - 1) * 2}px !important;
  }

  .m-#{($i - 1) * 2} {
    margin: #{($i - 1) * 2}px !important;
  }
  .lh-#{$i} {
    line-height: #{$i}px;
  }
  .lh-percent-#{$i} {
    line-height: #{$i}#{'%'};
  }
  .w-#{$i}px {
    width: #{$i}px;
  }

  .h-#{$i}px {
    height: #{$i}px;
  }

  .max-h-#{$i}px {
    max-height: #{$i}px;
  }

  .max-w-#{$i}px {
    max-width: #{$i}px;
  }

  .min-h-#{$i}px {
    min-height: #{$i}px;
  }

  .min-w-#{$i}px {
    min-width: #{$i}px;
  }
  .gap-#{$i}px {
    gap: #{$i}px;
  }
  @each $shortD, $dir in $directions {
    .p-#{$shortD}-#{($i - 1) * 2} {
      padding-#{$dir}: #{($i - 1) * 2}px !important;
    }

    .m-#{$shortD}-#{($i - 1) * 2} {
      margin-#{$dir}: #{($i - 1) * 2}px !important;
    }
  }
}

.color-8d8d8d{
  color: #8d8d8d;
}

.tooltip-box {
  border: 1px solid #484855 !important;
  background-color: #26263f !important;
  color: #92929f !important;
  font-size: 12px !important;
  line-height: 18px !important;
  border-radius: 8px !important;
  padding: 10px 20px !important;
  max-width: 240px !important;
  font-weight: 400;
  word-break: break-all;
}
.tooltip-box::after {
  border: 1px solid #484855 !important;
  background-color: #26263f !important;
}

.line-creator{
  width: 100%;
  height: 1px;
  background: var(--c-border-line-creator);
}
.line-mint {
  width: 100%;
  height: 1px;
  background: var(--c-border-line-mint);
}

.custom-modal-content {
   min-width: 600px;
   min-height: 614px;
 }
.cursor-pointer {
  cursor: pointer;
}
// quick mint 
.wrapper-adm-page {
  display: flex;
  flex-direction: column;
  gap: 16px;
  font-family: 'Roboto';
}
.wrapper-header-adm {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 84px;
  border-radius: 8px;
  background: #1B1B35;
  color: #FFFFFF;
  font-size: 18px;
  font-weight: 700;
  padding-left: 32px;
  padding-right: 32px;
}
.wrapper-content-adm {
  border-radius: 8px;
  padding: 32px;
  background: #1B1B35;
  color: #8787D6;
  width: 100%;
}
.deploy-adm {
  min-width: 664px;
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.console-adm {
  display: flex;
  flex-direction: column;
  gap: 16px;
  // height: 788px;
}
.wrapper-input-adm {
  display: flex;
  flex-direction: column;
  gap: 4px;
  align-items: start;
}
.title-input-adm {
  font-size: 14px;
  font-weight: 700;
  line-height: 16.41px;
}
.custom-input-adm {
  border-radius: 8px;
  border: none;
  height: 50px;
  width: 100%;
  font-size: 14px;
  font-weight: 500;
  font-family: 'Roboto';
  line-height: 16.41px;
  color: #777777;
  padding: 8px 20px 8px 12px;
  background: #0E0F29;
}
.button-adm {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 700;
  border: none;
  border-radius: 8px;
  color: #FFFFFF;
  background: #2F8AF5;
  width: 100%;
}

.btn-paste{
  background: #323249;
  color: #FFFFFF;
  cursor: pointer;
  border-radius: 6px;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  font-size: 14px;
  font-weight: 700;
}

.button-adm:hover {
  opacity: 0.7;
}
.validation-adm {
  font-size: 12px;
  line-height: 14.06px;
  font-weight: 500;
  color: #8D8D8D;
}
.line-adm {
  border-bottom: 1px solid #2D2D4F;
  width: 100%;
}
.header-console {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  font-weight: 700;
}
.terminal-scroll-adm {
  max-width: 598px;
  overflow: hidden;
  overflow-y: auto;
  clear: both;
  word-wrap: break-word;
  &::-webkit-scrollbar {
    width: 4px;
    height: 8px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 10px;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #dbdbdb;
    border-radius: 10px;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #888;
  }
}
.terminal-adm {
  overflow: auto;
  text-align: start;
}
.terminal-text {
  font-size: 12px;
  font-weight: 500;
  line-height: 14.06px;
  color: #777777;
  padding-right: 6px;
  padding-bottom: 8px;
}
@media (max-width: 1920px) {
  .main-app {
    margin: 30px auto;
  }
}
//modal quick mint 
.btn-detail-nft {
  margin: 24px 0 50px;
  width: 100%;
  height: 50px;
  border-radius: 8px;
  // background-color: $blue-color-primary;
  display: grid;
  place-items: center;
  font-weight: 700;
  cursor: pointer;
  color: #ffffff;
  &:hover {
    // background-color: $blue-hover-primary;
  }

  @media (max-width: 768px) {
    height: 48px;
    font-size: 14px;
  }
}
//flex custom
.flex-start {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.flex-between {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.vertical-align-unset {
  vertical-align: unset;
}
//select quick admin
.dropdown-toggle-mint {
  border: 1px solid var(--c-border-line-dd);
  border-radius: 6px;
  height: 44px;
  font-weight: 400;
  color: var(--text-select-mint);
  font-size: 16px;
  padding-left: 20px;
  .i {
    max-height: 24px;
  }
  &:hover {
    color: #525298;
  }
  &::after {
    width: 16px;
    height: 16px;
  }
}

.dropdown-menu-mint {
  background: var(--background-modal-mint);
  border: 1px solid #525298;
  li {
    button:hover {
      background: #FFFFFF1A;
    color: var(--text-select-mint);
    }
  }
}