// f-table
.wrap-f-table {
  overflow: auto;
  width: calc(100% - 250px);
  transition: 0.3s;

  &.wide {
    width: 100%;
  }

  * {
    transition: 0.3s;
  }
}

.f-table {
  display: flex;
  flex-direction: column;
  overflow-x: auto;
  color: #030229;
  font-size: 14px;
  gap: 15px;
  align-items: stretch;
  min-width: fit-content;
  justify-content: start;

  .tbody {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }

  .tr {
    // flex: 1 0 auto;
    flex: 0 0 auto;
    gap: 15px;
    width: 100%;
    min-width: fit-content;
    justify-content: stretch;
    display: flex;
    align-items: center;
  }

  .th {
    display: flex;
    flex: 1 0 auto;
    font-weight: 500;
    font-size: 16px;
    // margin-bottom: 5px;
  }

  .td {
    display: flex;
    flex: 1 0 auto;
    overflow: hidden;
    flex-wrap: wrap;
    display: flex;
    align-items: center;

    h5,
    time {
      white-space: nowrap;
    }

    &.pull-right {
      text-align: right;
      display: flex;
      justify-content: flex-end;
    }

    .link,
    .item {
      flex-direction: row;
      overflow: hidden;
      display: flex;
      align-items: center;
    }

    .thumb {
      width: 17px;
      height: 17px;
      border-radius: 100%;
      margin-right: 5px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
    }

    .klay {
      max-width: 100%;
      margin-bottom: 0;

      &:before {
        width: 17px;
        height: 17px;
        margin-right: 5px;
      }

      span {
        color: #6f6f6f;
        font-weight: normal;
        // white-space: nowrap;
        margin-left: 0;
        // display: block;
        width: 100%;
      }
    }

    .txt {
      max-width: calc(100% - 40px);
      display: flex;
      flex-direction: column;
      gap: 4px;

      h4 {
        font-weight: 600;
        font-size: 16px;
        margin-bottom: 0;
        // margin-bottom: 6px;
        @include t-ell;
      }

      h6 {
        margin-bottom: 0;
        max-width: 100%;
        @include t-ell;
      }

      h5 {
        @include t-ell;
      }

      span {
        font-weight: 400;
        font-size: 12px;
        display: inline-block;
        @include t-ell;
      }
    }
  }

  .td .btn {
    font-size: 12px;
    height: 25px;
    padding: 0;
    width: 65px;
    // width: 100%;
  }

  .i {
    margin-right: 8px;
    width: 17px;
  }

  &.table-profile {
    border: 1px solid rgba(70, 79, 96, 0.3);
    box-sizing: border-box;
    border-radius: 20px;
    gap: 0;

    .tr {
      padding: 0 40px 25px 40px;

      &:first-child {
        padding: 12px 40px;
        border-bottom: 0.5px solid #d4d4d4;
      }

      &:nth-child(2) {
        padding-top: 25px;
      }

      &.empty {
        background: rgba(217, 224, 236, 0.2);

        span {
          font-weight: 500;
          font-size: 14px;
          height: 29px;
          line-height: 29px;
          width: 106px;
          left: 668px;
          background: #ffffff;
          border-radius: 23.5px;
          margin: 0 auto;
        }
      }
    }

    .th {
      font-weight: 500;
      font-size: 14px;
      margin-bottom: 0;
    }

    .td {
      .btn-cancel {
        font-weight: 500;
        font-size: 14px;
        line-height: 21px;
        width: 95px;
        height: 29px;
        max-width: none;
      }

      h5 {
        font-weight: 400;
        font-size: 14px;
        margin-bottom: 0;
      }

      .action {
        img {
          height: 20px;
          margin-right: 8px;
        }

        h5 {
          font-weight: 500;
        }
      }

      .price {
        display: flex;
        align-items: center;

        .i {
          width: 28px;
          height: 28px;
          margin-right: 15px;
        }

        h5 {
          margin-bottom: 2px;
        }

        h6 {
          font-weight: 400;
          font-size: 12px;
          color: #6f6f6f;
          margin-bottom: 0;
        }
      }

      .link.item {
        .thumb {
          width: 29px;
          height: 29px;
          border-radius: 6px;
          margin-right: 12px;
        }

        .txt {
          h6 {
            font-weight: 400;
            font-size: 12px;
            margin-bottom: 0;
          }
        }
      }
    }

    .th,
    .td {
      &:nth-child(1),
      &:nth-child(2) {
        width: 170px;
      }

      &:nth-child(3) {
        width: 130px;
      }

      &:nth-child(4) {
        width: 100px;
      }

      &:last-child {
        width: 95px;
      }
    }

    &.table-profile-offer {
      .th,
      .td {
        &:nth-child(5) {
          width: 110px;
        }

        &:nth-child(6) {
          width: 55px;
        }
      }
    }

    &.table-profile-activity {
      .th,
      .td {
        &:nth-child(1) {
          width: 100px;
        }

        &:nth-child(2) {
          width: 150px;
        }

        &:nth-child(3) {
          width: 100px;
        }

        &:nth-child(4) {
          width: 65px;
        }

        &:nth-child(5),
        &:nth-child(6) {
          width: 95px;
        }

        &:last-child {
          width: 123px;
        }
      }
    }
  }

  &.table-recent {
    gap: 15px;

    .th,
    .td {
      &:nth-child(1) {
        width: 350px;
      }

      &:nth-child(2) {
        width: 120px;
      }

      &:nth-child(3),
      &:nth-child(4) {
        width: 160px;
      }

      &:nth-child(5) {
        width: 140px;
      }
    }

    .tr {
      background: rgba(217, 224, 236, 0.2);
      border-radius: 9.59812px;
      padding: 8px 30px;
    }

    .th {
      font-weight: 500;
      font-size: 12px;
    }

    .td {
      .klay:before,
      .thumb {
        width: 19px;
        height: 19px;
        margin-right: 7px;
      }

      // here
      a.link,
      .item {
        overflow: hidden;
        display: flex;
        align-items: center;

        &.item {
          .thumb {
            width: 48px;
            height: 48px;
            border-radius: 6px;
            margin-right: 12px;
          }

          .txt {
            max-width: calc(100% - 60px);
          }
        }
      }

      h5 {
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
        margin-bottom: 0;
        max-width: 100%;
      }
    }
  }

  &.table-detail {
    gap: 10px;

    .tr {
      justify-content: space-between;
    }

    .td {
      flex: 0 0 auto;
    }

    .td:last-child {
      text-align: right;
    }
  }

  &.table-offer,
  &.table-listing {
    .th,
    .td {
      &:nth-child(1) {
        width: 100px;
      }

      &:nth-child(2) {
        width: 120px;
      }

      &:nth-child(3) {
        width: 85px;
      }

      &:nth-child(4) {
        width: 90px;
      }

      &:nth-child(5) {
        width: 75px;
      }
    }
  }

  &.table-activity {
    min-height: 280px;

    .td .klay:before {
      width: 14px;
      height: 14px;
      margin-right: 7px;
    }

    .thead .tr {
      border-bottom: 0.5px solid #d4d4d4;
      padding-bottom: 5px;
    }

    .th {
      font-weight: normal;
    }

    .th,
    .td {
      &:nth-child(1) {
        width: 105px;
      }

      &:nth-child(2) {
        width: 80px;
      }

      &:nth-child(3),
      &:nth-child(4) {
        width: 100px;
      }

      &:nth-child(5) {
        width: 125px;

        a {
          display: flex;
          width: 100%;
          justify-content: space-between;
          margin-right: 35%;
        }
      }
    }
  }
  &.list-dark {
    color: #fff;
    margin-top: 20px;
    margin-bottom: 40px;
    width: 100%;
    min-width: 625px;
    .tr {
      padding-left: 12px;
    }
    .th {
      font-weight: 500;
      font-size: 12px;
      line-height: 20px;
    }
    .tbody {
      gap: 37px;
    }
    .tbody .tr {
      position: relative;
      height: 80px;
      background: linear-gradient(
        163.79deg,
        rgba(218, 218, 218, 0.2) 15.07%,
        rgba(92, 92, 92, 0.2) 46.91%,
        rgba(46, 46, 46, 0.4) 81.53%
      );
      border-radius: 12px;
      &::before {
        content: "";
        position: absolute;
        inset: 0;
        border-radius: 12px;
        padding: 1.5px;
        background: conic-gradient(
          from 268.91deg at 55.32% 42.81%,
          rgba(203, 203, 203, 0) 0deg,
          #979797 91.36deg,
          #6f6f6f 140.48deg,
          #535353 221.25deg,
          rgba(23, 23, 23, 0) 360deg
        );
        mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
        -webkit-mask: linear-gradient(#fff 0 0) content-box,
          linear-gradient(#fff 0 0);
        -webkit-mask-composite: xor;
        mask-composite: exclude;
      }
    }
    .thumb {
      width: 96px;
      height: 96px;
      border: 2px solid #000000;
      border-radius: 12px;
      margin-top: -26px;
    }
    .td {
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      color: #ffffff;
      &:nth-child(2) {
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
      }
    }
    .td,
    .th {
      &:nth-child(1) {
        width: 96px;
        flex: 0 0 auto;
      }
      &:nth-child(2) {
        width: 105px;
      }
      &:nth-child(3) {
        width: 100px;
      }
      &:nth-child(4) {
        width: 85px;
      }
      &:nth-child(5) {
        width: 65px;
      }
    }
  }
}

.f-table.table-profile .tr.wrap-empty {
  display: block;
  border-radius: 0;
  border: 0;
  padding: 80px;
  text-align: center;
}

.wrap-f-table.wide {
  .table-recent {
    // gap: 24px;

    .tr {
      padding: 9px 31px;
    }

    .th {
      font-size: 14px;
    }

    .td {
      h4 {
        font-size: 18px;
      }

      span {
        font-size: 14px;
      }

      h5 {
        font-size: 16px;
      }

      .klay:before,
      .thumb {
        width: 24px;
        height: 24px;
        margin-right: 10px;
      }
    }
  }
}

// @media (max-width: 991.98px)
@include media-breakpoint-down(md) {
  .wrap-f-table {
    width: 100%;
  }

  div.f-table {
    min-width: 0;
    gap: 18px;

    // gap: 12px;
    .th {
      font-size: 12px;
      margin-bottom: 0;
    }

    .td {
      .klay {
        span {
          font-size: 10px;
        }
      }
    }

    &.table-activity {
      .tr:first-child {
        border: 0;
        padding-bottom: 0;
      }

      .th:nth-child(5),
      .td:nth-child(5) {
        width: 190px;
      }
    }

    &.table-recent {
      .thead {
        display: none;
      }

      .tr {
        flex-wrap: wrap;
        padding: 0;
        background: #fff;
        border: 2px solid #f6f9fb;
        border-radius: 18px;
        gap: 16px 10px;
        overflow: hidden;
      }

      .td:nth-child(3),
      .td:nth-child(4) {
        display: none;
      }

      .td:nth-child(1) {
        width: 100%;
        padding: 16px;
        background: #f6f9fb;
        height: 85px;

        a.link {
          position: absolute;
          left: 0;
          right: 0;
          top: 0;
          bottom: 0;
          // padding-left: 36px;
          padding: 0 16px;
        }
      }

      .td:nth-child(2) {
        width: 40%;
        padding: 22px 0 18px 16px;
      }

      .td:nth-child(5) {
        width: 50%;
        padding: 22px 16px 18px 0;

        a {
          width: 100%;
        }
      }

      .td {
        position: relative;

        .num {
          margin-right: 16px;
        }

        span.label {
          font-weight: 500;
          font-size: 12px;
          color: #464f60;
          position: absolute;
          top: 0;
        }
      }
    }
  }
}
