// calendar
.btn-calendar {
  position: relative;
  width: 50px;
  height: 50px;
  flex: 0 0 auto;
  overflow: hidden;
  padding: 0;
  color: transparent;
  background: rgba(217, 224, 236, 0.4);
  display: inline-flex;
  align-items: center;
  justify-content: center;

  &:before {
    background-image: url(../img/i_calendar.svg);
    width: 21px;
    height: 21px;
    @include i-bg;
  }

  .form-control {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    color: transparent;
    background: transparent;
    padding: 0 !important;
    font-size: 16px;
    height: 50px;
    background: rgba(217, 224, 236, 0.2);
    border-radius: 100px;
    border: 0;
  }
}

.flatpickr-calendar {
  margin: 10px;
  width: 393px;
  height: 449px;
  padding: 30px 20px 20px 20px;
  // background: var(--background-modal);
  // box-shadow: 4px 4px 4px rgba(183, 183, 183, 0.25);
  // border-radius: 25px;
  border-radius: 5px;
  text-align: center;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));

  &.arrowTop:before,
  &.arrowTop:after {
    display: none;
  }

  .flatpickr-innerContainer {
    margin: 10px auto 20px auto;
    padding-bottom: 20px;
    text-align: center;
    border-bottom: 0.8px solid rgba(70, 79, 96, 0.3);
  }

  .flatpickr-days {
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    width: 307.875px;
    margin: 0 auto;
  }

  .flatpickr-weekdays {
    width: 307.875px;
    margin: 0 auto;
    margin-bottom: 10px;
  }

  .flatpickr-rContainer {
    flex: 0 0 100%;
    max-width: 100vw;
  }

  .flatpickr-current-month {
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 0.38px;
    color: #030229;
    padding-top: 2px;
  }

  .flatpickr-months {
    max-width: 100vw;
    display: flex;
    align-items: center;
  }

  .flatpickr-months .flatpickr-month {
    width: auto;
    max-width: 100vw;
  }

  .flatpickr-months .flatpickr-prev-month,
  .flatpickr-months .flatpickr-next-month {
    padding: 2px 10px;
    // position: relative;
    top: 28px;
    left: 48px;

    &:after {
      background-image: url(../img/i_arr_a.svg);
      @include i-bg;
      width: 5px;
      height: 10px;
    }

    svg {
      display: none;
    }
  }

  .flatpickr-months .flatpickr-next-month {
    left: auto;
    right: 48px;
  }

  .flatpickr-current-month span.cur-month {
    margin-left: 28px;
  }

  .flatpickr-months .flatpickr-next-month {
    &:after {
      transform: rotate(-180deg);
    }
  }

  .flatpickr-current-month .flatpickr-monthDropdown-months,
  .flatpickr-current-month input.cur-year {
    font-weight: 700;
    cursor: default;
  }

  // .numInputWrapper>span {
  //   // display: none;
  // }

  .numInputWrapper:hover,
  .flatpickr-current-month span.cur-month:hover {
    background: transparent !important;
  }

  span.flatpickr-weekday {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: rgba(60, 60, 67, 0.6);
  }

  .flatpickr-day {
    font-weight: 400;
    font-size: 16px;

    // color: #030229;
    // &.selected {
    //   // color: #fff;
    // }
  }

  .flatpickr-day.flatpickr-disabled,
  .flatpickr-day.flatpickr-disabled:hover,
  .flatpickr-day.prevMonthDay,
  .flatpickr-day.nextMonthDay,
  .flatpickr-day.notAllowed,
  .flatpickr-day.notAllowed.prevMonthDay,
  .flatpickr-day.notAllowed.nextMonthDay {
    color: #808080;
  }

  &.hasTime .flatpickr-time {
    border-top: 0;
  }

  .flatpickr-time input.flatpickr-hour,
  .flatpickr-time input.flatpickr-minute,
  .flatpickr-time input.flatpickr-second {
    // background: rgba(217, 224, 236, 0.2);
    background: #f0f5ff;
    border-radius: 5px;
    padding: 0 30px;
    font-weight: 400;
    font-size: 16px;
    color: #030229;
  }

  .flatpickr-time .flatpickr-time-separator {
    margin: 0 8px;
  }

  .flatpickr-day.selected,
  .flatpickr-day.startRange,
  .flatpickr-day.endRange,
  .flatpickr-day.selected.inRange,
  .flatpickr-day.startRange.inRange,
  .flatpickr-day.endRange.inRange,
  .flatpickr-day.selected:focus,
  .flatpickr-day.startRange:focus,
  .flatpickr-day.endRange:focus,
  .flatpickr-day.selected:hover,
  .flatpickr-day.startRange:hover,
  .flatpickr-day.endRange:hover,
  .flatpickr-day.selected.prevMonthDay,
  .flatpickr-day.startRange.prevMonthDay,
  .flatpickr-day.endRange.prevMonthDay,
  .flatpickr-day.selected.nextMonthDay,
  .flatpickr-day.startRange.nextMonthDay,
  .flatpickr-day.endRange.nextMonthDay {
    // background: #fc6825;
    // border-color: #fc6825;
    background: #2f80ed;
    border-color: #2f80ed;
  }
}

.wrap-calendar .flatpickr-calendar {
  filter: none;
}

.pop-calendar.modal {
  .modal-dialog {
    width: 878px;
    max-width: 1000px;
  }

  .modal-content {
    width: 878px;
    max-width: 1000px;
    background: #f7f9fc;
  }

  .modal-body {
    padding: 45px 30px 40px 30px;
    gap: 32px;
    display: flex;
    align-items: center;

    h2.heading {
      font-weight: 700;
      font-size: 22px;
      margin-bottom: 20px;
    }

    input.form-control {
      display: none;
    }
  }
}

.form-check-input:checked + .form-checked-content {
  opacity: 0.5;
}

.form-check-input-placeholder {
  pointer-events: none;
  border-style: dashed;
}

// here
[contenteditable]:focus {
  outline: 0;
}

#btnInputDate {
  position: relative;

  input {
    position: absolute;
    right: 0;
    top: 0;
    z-index: 10;
    width: auto;
    // opacity: 0;
  }
}
