// class, placeholder

// specific styles
.pull-right {
  margin-left: auto;
}

.pull-btm {
  margin-top: auto;
}

.pull-center {
  margin: 0 auto;
}

.overflow-auto-y {
  overflow-y: auto;
}

.t-ell {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.t-ell2 {
  @include t-ell2;
}

.w-20 {
  width: 20%;
}

.w-35 {
  width: 35%;
}

svg.fill-w {
  fill: $c-w;
}

svg.fill-b {
  fill: $c-b;
}

svg.fill-a1 {
  fill: $c-a1;
}

svg.fill-g0 {
  fill: $c-g0;
}

.c-w {
  color: $c-w  !important;
}

.c-b {
  color: $c-b  !important;
}

.c-err {
  color: $c-err  !important;
}

.c-a0 {
  color: $c-a0  !important;
}

.c-a1 {
  color: $c-a1  !important;
}

.c-a2 {
  color: $c-a2  !important;
}

.c-a3 {
  color: $c-a3  !important;
}

.c-g0 {
  color: $c-g0  !important;
}

.c-g1 {
  color: $c-g1  !important;
}

.c-g2 {
  color: $c-g2  !important;
}

.c-g3 {
  color: $c-g3  !important;
}

.c-g4 {
  color: $c-g4  !important;
}

.c-g5 {
  color: $c-g5  !important;
}

.c-g6 {
  color: $c-g6  !important;
}

// @media (max-width: 991.98px)
@include media-breakpoint-down(md) {
  .fixed-bottom {
    margin-bottom: 0;
    padding: 20px;
  }
}
// .right-0 {
//   margin-right: 0;
// }
.width-1200 {
  width: 1200px;
}