// range slider
.form-range::-webkit-slider-thumb,
.form-range::-moz-range-thumb {
  background-color: #fff;
}

// .form-range:focus::-webkit-slider-thumb,
// .form-range:focus::-moz-range-thumb {
//   // box-shadow: none;
// }

// double range
.slider-range-block {
  margin: 8px 0 0;

  .wrap-btn {
    width: 100%;
    text-align: center;
    margin-top: 18px;

    .btn {
      font-weight: 500;
      font-size: 12px;
      color: #464f60;
      width: 106px;
      height: 32px;
      background: rgba(217, 224, 236, 0.2);
    }
  }

  .double-range-container {
    border: 0;
    border-radius: 10px;
    height: 9px;
    background-color: #d9e0ec;
    margin: 0 auto;
    margin-bottom: 18px;
    width: 95%;
    user-select: none;
    box-sizing: border-box;
    white-space: nowrap;
  }

  .ui-slider-range {
    top: 0;
    position: absolute;
    background: linear-gradient(270deg, #ff2f00 0%, #fc6825 78.13%);
    bottom: 0;
  }

  .ui-slider {
    position: relative;
    width: 100%;
    height: 9px;
    top: 50%;
    transform: translate(0, -50%);
    background: #d9e0ec;
    box-shadow: none;
    border-radius: 10px;
  }

  .ui-slider-handle {
    border-color: #ff2f00;
    position: absolute;
    // top: 50%;
    top: 25%;
    width: 0;
    height: 0;
  }

  .ui-slider-handle:after {
    content: " ";
    box-sizing: border-box;
    position: absolute;
    transform: translate(-50%, -50%);

    width: 14px;
    height: 14px;
    border-radius: 20px;
    background: #ffffff;
    border: 3px solid #fc6825;
    box-sizing: border-box;
    box-shadow: 2px 2px 3px rgba(138, 138, 138, 0.25);
    margin-top: 2px;

    &:focus {
      outline: none;
    }
  }

  .ui-slider-handle:last-child {
    &:after {
      border-color: #ff2f00;
    }
  }

  .handle[data-which="end"]:after {
    transform: translate(-100%, -50%);
  }

  .slider-range-field {
    width: 75px;
    height: 30px;
    border: 0.8px solid #d9e0ec;
    box-sizing: border-box;
    border-radius: 8.89638px;
    background-color: #fff;
    font-size: 12px;
  }

  input[type="number"] {
    padding: 5px;
  }

  span {
    color: #969696;
    font-weight: 100;
  }
}
