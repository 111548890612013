.wrap-thumb {
  isolation: isolate;
  overflow: hidden;
}

.thumb {
  // background-size: cover;
  // background-repeat: no-repeat;
  // background-position: center center;
  flex: 0 0 auto;
  overflow: hidden;
  width: 100%;
  height: 100%;
  isolation: isolate;
  display: flex;
  align-items: center;
  border-radius: 16px;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &.thumb-contain img,
  &.thumb-contain video {
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
  }

  &.thumb-circle {
    border-radius: 100px;
  }
}