// width - page padding
$w-pp: 60px;

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px,
);

// color
$c-w: #ffffff;
$c-b: #030229;
$c-err: #ff4d4f;
$c-bg: rgba(217, 224, 236, 0.2);
$c-main: #2f80ed; 
$text-secondary: #595959;
// active
$c-a0: #ff2f00;
$c-a: #fc6825;
$c-a1: #fc6825;
$c-a2: #fdb492;
$c-a3: #fff0e9;

// grey
$c-g0: #464f60;
$c-g1: #626978;
$c-g2: #7d8490;
$c-g3: #ccd2e3;
$c-lg: #d9e0ec;
$c-g4: #d9e0ec;
$c-g5: #f0f2f4;
$c-g6: #f7f9fc;

// gradient
$grad: linear-gradient(90deg, #2f80ed 0%, #2fa9ed 78.13%);
$c-grad: linear-gradient(90deg, #2f80ed 0%, #2fa9ed 78.13%);
$c-bg-grad: linear-gradient(180deg, #fefeff -16%, #f4f5f8 357.67%);

//button 
$btn-hover: #0c76f2;
$btn-color: #2f80ed;