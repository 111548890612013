// tooltip
.tooltip.show {
  opacity: 1;
}

.tooltip-inner {
  background-color: #464f60;
  padding: 12px 20px;
  border-radius: 12px;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
}

.tooltip.bs-tooltip-right .tooltip-arrow:before {
  border-right-color: #464f60;
}

.tooltip.bs-tooltip-left .tooltip-arrow:before {
  border-left-color: #464f60;
}

.tooltip.bs-tooltip-bottom .tooltip-arrow:before {
  border-bottom-color: #464f60;
}

.tooltip.bs-tooltip-top .tooltip-arrow:before {
  border-top-color: #464f60;
}
