main .wrap-list {
  display: flex;

  // overflow: hidden;
  // padding: 5px;
  // margin: 0 -5px;
  aside+div {
    flex-grow: 1;
  }
}

.list-group {
  display: grid;
  gap: 10px;
  border: 0;
}

.list-group-checkable .list-group-item,
.btn-radio {
  cursor: pointer;
  border-radius: 15px;
  height: 66px;
  font-weight: 500;
  padding-left: 28px;
  background: #f4f5f8;
  // border: 0;
  border: 3px solid #f4f5f8;
  transition: none;
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 16px;

  .i {
    width: 35px;
  }
}

.list-group-item-check {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}

.list-group-item-check:hover+.list-group-item,
.btn-radio:hover {
  color: #2f80ed;
  background: #e6f7ff;
  border: 3px solid #e6f7ff;
}

.list-group-item-check:checked+.list-group-item {
  color: #2f80ed;
  background: #e6f7ff;
  border: 3px solid #2f80ed;
}

.list-group-item-check[disabled]+.list-group-item,
.list-group-item-check:disabled+.list-group-item {
  pointer-events: none;
  filter: none;
  opacity: 0.5;
}

// list-group
.b-example-divider {
  height: 3rem;
  background-color: rgba(0, 0, 0, 0.1);
  border: solid rgba(0, 0, 0, 0.15);
  border-width: 1px 0;
  box-shadow: inset 0 0.5em 1.5em rgba(0, 0, 0, 0.1),
    inset 0 0.125em 0.5em rgba(0, 0, 0, 0.15);
}

.bi {
  vertical-align: -0.125em;
  fill: currentColor;
}

.list-group {
  width: auto;
  max-width: 460px;
  margin: 30px auto;
  margin-bottom: 25px;
}

// @media (max-width: 991.98px)
@include media-breakpoint-down(md) {
  .wrap-list {
    // padding-top: 0;
    // padding-top: 2px;
    overflow: visible;
  }
}