.progress {
  background: #fff;
  border: 1px solid #ccd2e3;
  border-radius: 15px;
  height: 19px;
}

.progress-bar {
  background: $grad;
  border-radius: 15px;
}
