// page: profile
.profile-main {
  .wrap-f-table,
  .li-card-col {
    min-height: 350px;
  }
}

.profile-top {
  text-align: center;
  padding-bottom: 36px;
  border-bottom: 0.5px solid #d5d5d5;
  position: relative;
  padding-left: 60px;
  padding-right: 60px;

  .wrap-btn-share {
    position: absolute;
    right: 60px;
    top: 315px;
    padding: 6px;
  }

  .jumbotron {
    position: relative;
    height: 308px;
  }

  &.edit {
    .profile-intro {
      .btn-circle {
        width: 22px;
        height: 22px;
        margin: 0;

        img {
          width: 15px;
          height: 15px;
        }
      }
    }

    .link-thumb:hover .thumb:after,
    .jumbotron.thumb:hover:after {
      content: "";
      background: rgba(0, 0, 0, 0.3);
      background-image: url(../img/i_photo.svg);
      background-repeat: no-repeat;
      background-position: center center;
      display: inline-block;
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
    }
  }

  .profile-intro {
    // margin: 0 auto;
    margin-top: -50px;
    display: inline-block;
    text-align: center;
    flex-direction: column;
    display: inline-flex;
    align-items: center;
    justify-content: center;

    .link-thumb {
      position: relative;
      width: 100px;
      height: 100px;
      border: 4px solid #ffffff;
      border-radius: 18px;
      overflow: hidden;

      // .thumb {}
    }

    .input-group.input-edit {
      border-radius: 100px;
      background: rgba(217, 224, 236, 0.2);
      width: 193px;
      display: flex;
      align-items: center;
      margin: 10px 0;
      padding-right: 3px;

      input {
        border: 0;
        font-size: 18px;
        background: transparent;
        color: rgba(70, 79, 96, 0.6);
        padding: 8px 25px;
      }

      button {
        width: 40px;
        height: 40px;

        &:before {
          background: url(../img/btn_check_sm_g.svg);
          width: 18px;
          height: 19px;
          @include i-bg;
        }

        &:hover:before {
          background: url(../img/btn_check_sm_a.svg);
          width: 18px;
          height: 19px;
          @include i-bg;
        }

        // img {
        // 	width: 18px;
        // 	height: 18px;
        // }
      }
    }

    h2 {
      margin-top: 12px;
      font-weight: 600;
      font-size: 22px;
      color: #030229;
    }

    .link {
      font-weight: 400;
      font-size: 14px;
      line-height: 21px;
      color: #707070;
      display: inline-flex;
      align-items: center;
      justify-content: center;

      .i {
        margin-left: 8px;
      }
    }
  }
}

// @media (max-width: 991.98px)
@include media-breakpoint-down(md) {
  .profile-top {
    padding: 20px 20px 30px 20px;

    .jumbotron {
      height: 150px;
    }

    // .profile-intro {}

    .wrap-btn-share {
      right: 14px;
      top: 172px;
    }
  }

  .profile-main {
    .wrap-f-table .wrap-btn-filter {
      margin-bottom: 18px;
    }
  }

  .accordion.li-profile {
    border-radius: 18px;

    .accordion-header {
      display: flex;
    }

    .accordion-button {
      padding: 16px 17px;
      width: auto;
    }

    .link.item {
      padding: 16px 0 16px 17px;
      flex: 1 0 auto;
      max-width: calc(100% - 60px);
      display: flex;
      align-items: center;

      .thumb {
        width: 54px;
        height: 54px;
        border-radius: 8px;
        margin-right: 12px;
      }

      .txt {
        overflow: hidden;

        h6 {
          font-size: 14px;
          font-weight: 400;
          margin-bottom: 5px;
          @include t-ell;
        }

        h5 {
          font-size: 16px;
          font-weight: 600;
          margin-bottom: 0;
          @include t-ell;
        }
      }
    }

    .accordion-body {
      padding: 15px 17px;
      background: #fff;
      gap: 15px;

      .tr {
        justify-content: space-between;
        align-items: start;

        .wrap-btn {
          text-align: center;
          align-items: center;

          .btn {
            font-size: 14px;
            font-weight: 500;
            height: 29px;
            min-width: 95px;
            margin: 0 auto;
          }
        }
      }

      .label {
        font-weight: 500;
        font-size: 12px;
        color: #464f60;
      }

      .value {
        text-align: right;
        flex: 0 0 auto;

        h5 {
          font-weight: 400;
          font-size: 14px;
          color: #030229;
          margin-bottom: 0;
        }

        .action {
          img {
            margin-right: 5px;
            height: 14px;
          }
        }

        .link-a > h5 {
          color: #fc6825;
        }

        .price {
          flex-direction: column;
          text-align: right;

          .klay:before {
            width: 14px;
            height: 14px;
          }

          h5 {
            font-weight: 400;
            font-size: 14px;
            color: #030229;
            margin-bottom: 2px;
          }

          h6 {
            font-weight: 400;
            font-size: 12px;
            color: #6f6f6f;
            margin-bottom: 0;
          }
        }
      }
    }

    .accordion-header {
      background: #f6f9fb;
    }

    .accordion-button::after {
      background-image: url(../img/i_arr_round_g.svg);
      width: 22px;
      height: 22px;
    }

    .accordion-item {
      border: 2px solid #f6f9fb;
      box-shadow: none;
    }

    .accordion-item:last-of-type,
    .accordion-item:last-of-type .accordion-collapse {
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;
      margin-bottom: 0;
      box-shadow: none;
    }

    .td a.link,
    .td .item {
      .txt {
        flex: 1 0 auto;
      }
    }
  }
}
