// page: listing
.listing-top {
  display: block;
  width: 100%;
  background: rgba(217, 224, 236, 0.2);
  border-radius: 16px;
  margin: 0 0 50px 0;

  a {
    display: inline-flex;
    align-items: center;
    padding: 10px 15px;
    gap: 15px;

    &:before {
      background-image: url(../img/i_arr_bl.svg);
      width: 5px;
      height: 10px;
      @include i-bg;
    }

    .thumb {
      width: 53px;
      height: 53px;
      border-radius: 14px;
    }

    h6 {
      font-weight: 400;
      font-size: 12px;
      margin-bottom: 2px;
    }

    h5 {
      font-weight: 600;
      font-size: 18px;
      margin-bottom: 0;
    }
  }
}

.listing-con {
  > h2 {
    font-weight: 600;
    font-size: 26px;
    margin-bottom: 0;
  }

  .wrap-con {
    display: flex;
    padding-top: 50px;

    .thumb {
      border-radius: 22px;
      width: 445px;
      height: 445px;
      flex: 1 1 auto;
      margin-right: 42px;
      justify-content: center;

      img,
      video {
        border-radius: 22px;
      }
    }

    .wrap-txt {
      width: 100%;
      max-width: 620px;
    }

    .pull-right {
      input {
        margin-left: auto;
      }
    }

    .wrap-btm {
      border-top: 0.8px solid rgba(70, 79, 96, 0.3);
      padding-top: 20px;
      margin-top: 30px;

      .label {
        font-weight: 600;
        font-size: 18px;
      }

      .tr {
        display: flex;
        margin-top: 5px;

        .td {
          font-weight: 500;
          font-size: 14px;
          color: #464f60;

          &.pull-right {
            margin-left: auto;
          }
        }
      }

      .wrap-btn {
        margin-top: 35px;

        .btn-grad {
          width: 193px;
          height: 45px;
          font-weight: 600;
          font-size: 15px;
        }
      }
    }

    .wrap-form {
      display: flex;
      flex-direction: column;
      margin-top: 30px;
      gap: 13px;

      .select-selected {
        font-weight: 500;
        color: #464f60;
      }

      .form-control:not(#inputCalendar) {
        height: 45px;
        font-weight: 500;
        font-size: 14px;
        border: 1px solid #cbcbcb;
        border-radius: 14px;
        color: #464f60;
      }

      .btn-calendar,
      #inputCalendar {
        height: 45px;
        width: 45px;
        color: transparent;
      }

      .wrap-input {
        flex: 1 1 auto;
      }

      span.label {
        height: 45px;
        // border: 1px solid #CBCBCB;
        border-radius: 14px;
        flex: 0 0 auto;
        display: flex;
        align-items: center;
        font-weight: 500;
        font-size: 14px;
        width: 119px;
        background: rgba(217, 224, 236, 0.2);
        border: 0;
        color: #464f60;
        padding-left: 20px;
        padding-right: 20px;

        .i {
          width: 20px;
          height: 20px;
          margin-right: 8px;
        }
      }

      .dropdown {
        height: 45px;
        border: 1px solid #cbcbcb;
        border-radius: 14px;
        flex: 1 1 auto;
        min-width: 180px;
        display: flex;
        align-items: center;

        &.fix-w {
          flex: 0 0 auto;
          // width: 165px;
        }

        button {
          width: 100%;
          font-weight: 500;
          font-size: 14px;
          color: #464f60;

          .i {
            width: 18px;
            height: 18px;
            margin-right: 8px;
          }

          &:after {
            margin-left: auto;
          }
        }

        .dropdown-menu {
          width: 100%;
        }
      }

      .tr {
        display: flex;
        gap: 10px;
        align-items: center;
      }

      .td {
        display: flex;
        gap: 10px;
      }

      .label {
        font-weight: 600;
        font-size: 16px;
        width: 150px;
        // listing label fix
        flex: 0 0 auto;
        word-break: keep-all;

        & + div {
          flex: 1 1 auto;
        }
      }
    }

    .wrap-toggle {
      width: 332px;

      .toggle-innertxt {
        height: 43px;

        > input:checked + span span {
          &.toggle-fixed:before {
            background-image: url(../img/i_fixed_b.svg);
          }

          &.toggle-timed:before {
            background-image: url(../img/i_timed_w.svg);
          }
        }

        a {
          height: 35px;
          margin: 4px 0;
          width: 151px;
          right: 175px;
        }

        > input:checked + span + a {
          width: 175px;
          right: 6px;
        }
      }

      .toggle-innertxt > span {
        width: 332px;
      }

      .toggle-innertxt > span span {
        height: 43px;
        font-weight: 500;
        font-size: 16px;

        &:first-child {
          width: 151px;
          left: 5px;
        }

        &:last-child {
          width: 175px;
          left: 151px;
        }

        &.toggle-fixed:before {
          background-image: url(../img/i_fixed_w.svg);
          width: 17px;
          height: 17px;
          transition: 0.2s;
          margin-right: 6px;
          @include i-bg;
        }

        &.toggle-timed:before {
          background-image: url(../img/i_timed_b.svg);
          width: 18px;
          height: 18px;
          transition: 0.2s;
          margin-right: 6px;
          @include i-bg;
        }
      }
    }
  }
}

// @media (max-width: 991.98px)
@include media-breakpoint-down(md) {
  .listing-main {
    padding: 20px 20px 80px 20px;

    .listing-con {
      & > h2 {
        display: none;
      }

      .wrap-con {
        padding-top: 12px;
        flex-direction: column;

        .thumb.thumb-contain {
          width: calc(100vw - 40px);
          max-height: calc(100vw - 40px);
          height: auto;

          img,
          video {
            max-height: calc(100vw - 40px);
          }
        }
      }
    }
  }

  .listing-con .wrap-con .wrap-toggle .toggle-innertxt {
    margin-top: 24px;
  }

  .listing-con .wrap-con .wrap-toggle .toggle-innertxt > span span {
    font-size: 14px;
  }

  .listing-con .wrap-con .wrap-form {
    margin-top: 24px;
    gap: 24px;

    .tr {
      position: relative;
      flex-wrap: wrap;
      margin-top: 0;
      gap: 15px;

      .form-switch.pull-right {
        position: absolute;
        right: 0;
        top: 0;
      }
    }
  }

  .listing-con .wrap-con .wrap-btm .tr .td {
    font-size: 12px;
  }

  .listing-con .wrap-con .wrap-form .label {
    width: 100%;
  }

  .listing-con .wrap-con .wrap-btm .wrap-btn .btn-grad {
    width: 100%;
  }

  .listing-top {
    margin: 0;

    a {
      width: 100%;
    }
  }
}
