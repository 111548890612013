aside {
  flex: 0 0 auto;
  width: 230px;
  // padding-right: 20px;
  // margin-right: 20px;

  &.side-filter {
    // transition: 0.3s ease;
    max-height: calc(100vh - 153px);
    overflow: auto;
    top: 153px;
    z-index: 1015;

    .accordion {}

    .slider+div {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    &.hide {
      // transition: 0.3s ease;
      display: none;
    }

    .wrap-con {
      display: flex;
      flex-direction: column;
      gap: 11px;
    }

    &+section {
      align-self: flex-start;
      padding-bottom: 15px;

      &+.li-card-col {
        width: calc(100% - 250px);

        &.wide {
          width: 100%;
        }
      }
    }
  }

  .btn-side {
    display: flex;
    align-items: center;
    flex: 1 0 auto;
    width: 100%;
    height: auto;
    padding: 6px 16px;
    background-color: rgba(220, 220, 220, 0.2);
    background: rgba(217, 224, 236, 0.2);
    border-radius: 9px;
    font-weight: 500;
    min-height: 32px;
    text-align: left;

    &:hover {
      background-color: rgba(220, 220, 220, 0.2);
    }

    .label {
      font-weight: 500;
      font-size: 13px;
      flex: 1 1 100%;
    }

    .badge {
      font-size: 8px;
      color: #030229;
      font-weight: 500;
      background: rgba(217, 224, 236, 0.4);
      border-radius: 100px;
      margin-left: 11px;
      padding: 4px 10px;
      // min-width: 37px;
      margin-top: 2px;
    }

    &.active {
      background: rgba(252, 104, 37, 0.1);
      border: 1px solid #fc6825;
      color: #fc6825;

      .badge {
        background-color: #fc6825;
        color: #fff;
      }
    }
  }
}

// @media (max-width: 991.98px)
@include media-breakpoint-down(md) {
  aside.side-filter {
    .wrap-con.wrap-btn-row {
      flex-direction: row;
      flex-wrap: wrap;

      .btn-side {
        flex: 1 0 auto;
        width: 45%;
      }
    }
  }

  aside.side-filter {
    display: none;

    +.li-card-col {
      width: 100%;
    }

    .accordion {
      overflow-y: auto;
    }

    &.show {
      display: block;
      // display: flex;
      // flex-direction: column;
      width: 100%;
      max-height: 100%;
      height: 100vh;
      // position: absolute;
      position: fixed;
      left: 0;
      top: 0;
      overflow-x: hidden;
      overflow-y: auto;
      z-index: 1040;
      background-color: #fff;
      padding-bottom: 100px;
    }

    .accordion-item {
      margin-bottom: 0;
      border: 0;
      border-radius: 0;
      box-shadow: none;
      border-bottom: 1.2px solid #f0f2f4;
      background: #fff;
    }

    .accordion-button {
      padding: 20px;
    }

    .slider-range-block input[type="number"] {
      height: 32px;
      width: 45%;
    }

    .filter-top {
      display: flex;
      padding: 20px;
      align-items: center;

      .btn.link {
        font-weight: 500;
        font-size: 13px;
        color: #9ba4bb;
        position: absolute;
        left: 20px;
      }

      h2 {
        font-weight: 600;
        font-size: 20px;
        margin: 0 auto;
      }

      .btn-close {
        margin-left: auto;
        background-size: 12px 12px;
        position: absolute;
        right: 20px;
      }
    }
  }
}