.pagination {
  justify-content: center;
  align-items: center;
  &.nav {
    border-bottom: 0;
  }
  .page-item {
    display: flex;
    align-items: center;
    &.current-page {
      font-weight: 400;
      font-size: 18px;
      line-height: 30px;
      color: var(--text-primary);
      padding: 0 10px;
    }
  }
}
