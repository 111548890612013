.form-connect {
  width: min(376px, 100%);
  background: var(--background-modal);
  box-shadow: 0px 4px 49px rgba(20, 161, 255, 0.1);
  border-radius: 20px;
  display: flex;
  align-items: center;
  margin: 0 auto;
  font-weight: 700;
  font-size: 20px;
  flex-direction: column;
  .title-head {
    color: var(--text-primary);
    padding: 18px 0;
  }
  .line-connect {
    width: 100%;
    border: 1px solid rgba(255, 255, 255, 0.3);
  }
  .wrapper-content-connect {
    padding: 18px 40px 32px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    align-items: center;
    width: 100%;
    .title-content {
      display: flex;
      align-items: center;
      font-size: 16px;
      font-weight: 400;
      color: var(--text-primary);

    }
    .list-mainnet {
      height: 86px;
      width: 100%;
      padding: 0 20px;
      background: var(--background-box-wallet);
      border-radius: 8px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .box-mainnet {
        width: 54px;
        height: 54px;
        background: var(--bg-box-mainnet);
        border-radius: 4px;
        cursor: pointer;
        display: grid;
        place-items: center;
        .icon-mainnet {
          width: 36px;
          height: 36px;
          img {
            width: 100%;
            height: 100%;
          }
        }
        // &:hover {
        //   background: #2f8af5;
        // }
        &.unused {
          background-color: rgba(121, 121, 121, 0.18);
          color: white;
          cursor: default;
        }
        &.focused {
          background: #2f8af5;
        }
      }
    }
    .list-wallet {
      background: var(--background-box-wallet);
      border-radius: 8px;
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 16px;
      padding: 16px 0;
      .box-item-wallet {
        height: 48px;
        background: #ffffff;
        border-radius: 4px;
        margin: 0 16px;
        display: flex;
        justify-content: flex-start;
        gap: 8px;
        padding: 12px 20px;
        align-items: center;
        cursor: pointer;
        .name-wallet {
          color: #1f1f1f;
          font-weight: 400;
          font-size: 14px;
        }
        .icon-wallet {
          width: 24px;
          height: 24px;
          img {
            width: 100%;
            height: 100%;
            vertical-align: baseline;
          }
        }
        &:hover {
          background: #c2cde1;
        }
      }
    }
  }
}